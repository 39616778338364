import React, { useRef, useEffect, useState } from "react";
import Error from "./Error";

const CompanySearchField = ({
  query,
  options,
  error,
  isLoading,
  showDropdown,
  onQueryChange,
  onOptionClick,
  onFocus,
  onBlur,
  onSubmit,
  placeholder,
  placeholder2,
  className = "",
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherInputValue, setOtherInputValue] = useState("");

  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const otherInputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        onBlur();
        setShowOtherInput(false); // Close "Другое" input if clicking outside
        if (query === "Другое") {
          onQueryChange({ target: { value: "" } });
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onBlur, query, onQueryChange]);

  useEffect(() => {
    if (query && query !== "Другое") {
      const newFilteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredOptions(newFilteredOptions);
    } else {
      setFilteredOptions(options);
    }
  }, [query, options]);

  const handleInputChange = (e) => {
    if (!isOptionSelected && !showOtherInput) {
      onQueryChange(e);
    } else if (showOtherInput) {
      setOtherInputValue(e.target.value);
      onQueryChange(e); // Optionally pass the change back to the parent component if needed
    }
    setIsOptionSelected(false);
    onFocus();
  };

  const handleInputFocus = () => {
    onFocus();
    if (query === "Другое") {
      onQueryChange({ target: { value: "" } });
      setShowOtherInput(false);
    }
    setFilteredOptions(options); // Show full list of options
  };

  const handleOptionClick = (option) => {
    setIsOptionSelected(true);
    onQueryChange({
      target: { value: option.value },
    });
    onOptionClick(option);
    onBlur();
    setShowOtherInput(false);
  };

  const handleOtherClick = () => {
    setShowOtherInput(true);
    onQueryChange({
      target: { value: "Другое" },
    });
    setIsOptionSelected(true);
    onBlur();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (showOtherInput) {
      onSubmit(otherInputValue); // Submit the value from the "Другое" input
    } else {
      onSubmit(query); // Submit the value from the main input
    }
  };

  return (
    <form onSubmit={handleSubmit} className={`w-full ${className}`}>
      <div className="relative">
        <div
          className={`flex w-full border-1 ${
            error
              ? "border-[#FFDCDB] bg-main-lorange"
              : "border-main-grayb bg-main-whitebg"
          }`}
        >
          <input
            ref={inputRef}
            className="bg-inherit rounded-none outline-none w-full px-6 py-4 mob:px-5"
            type="text"
            placeholder={placeholder}
            value={showOtherInput ? "Другое" : query}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            readOnly={showOtherInput} // Make input read-only when "Другое" is selected
          />
          <svg
            className={`w-6 mx-7 mob:mx-5 mob:w-5 ${
              error ? "hidden" : "block"
            }`}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 13L19 19M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15Z"
              stroke="#949494"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {error && <Error error={error} />}
        {/* Show dropdown only if the secondary input ("Другое") is not active */}
        {showDropdown && !showOtherInput && (
          <ul
            ref={dropdownRef}
            className="absolute left-0 w-full top-full z-10 bg-main-whitebg overflow-y-scroll max-h-64"
          >
            {isLoading && (
              <div className="p-6 py-4 border-1 border-main-whiteb bg-main-awhite leading-105 -tracking-3 text-f14">
                Поиск...
              </div>
            )}
            {!isLoading && filteredOptions.length > 0 ? (
              <>
                {filteredOptions.map((option) => (
                  <li
                    key={option.value}
                    onClick={() => handleOptionClick(option)}
                    className="cursor-pointer group p-6 py-4 flex flex-row justify-between items-center border-1 border-main-whiteb bg-main-awhite transition-all hover:text-main-orange hover:bg-main-lorange hover:border-main-orangeb leading-105 -tracking-3 text-f14"
                  >
                    {option.label}
                  </li>
                ))}
                <div
                  onClick={handleOtherClick}
                  className="cursor-pointer transition-all hover:text-main-orange hover:bg-main-lorange hover:border-main-orangeb p-6 py-4 border-1 border-main-whiteb bg-main-awhite leading-105 -tracking-3 text-f14"
                >
                  Другое
                </div>
              </>
            ) : (
              !isLoading &&
              query.trim() !== "" && (
                <div
                  onClick={handleOtherClick}
                  className="cursor-pointer transition-all hover:text-main-orange hover:bg-main-lorange hover:border-main-orangeb p-6 py-4 border-1 border-main-whiteb bg-main-awhite leading-105 -tracking-3 text-f14"
                >
                  Другое
                </div>
              )
            )}
          </ul>
        )}
        {showOtherInput && (
          <div className="mt-4">
            <input
              ref={otherInputRef}
              type="text"
              className="bg-inherit outline-none w-full px-6 mob:px-5 rounded-none border-1 py-3.5 border-main-grayb"
              placeholder={placeholder2} // Inherits the same placeholder
              value={otherInputValue}
              onChange={handleInputChange} // Use the same handleInputChange
              autoFocus // Automatically focuses on this input when "Другое" is selected
            />
          </div>
        )}
      </div>
    </form>
  );
};

export default CompanySearchField;
