import React from "react";

const CheckboxField = ({ name, checked, label, error, onChange }) => {
  return (
    <label
      className={`main-checkbox text-f14 mob:text-f13  -tracking-3 leading-105 block relative cursor-pointer ps-7 mob:px-0  ${
        error ? "text-main-red" : "text-main-gray2"
      }`}
    >
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        required
      />
      <span className="text-label">{label}</span>

      <div className="checkmark"></div>
    </label>
  );
};

export default CheckboxField;
