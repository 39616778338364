import Error from "./Error";

const InputField = ({
  name,
  type,
  placeholder,
  value,
  required = false,
  readOnly = false,
  error,
  onChange,
  className = "",
}) => {
  return (
    <div className="w-full relative">
      <textarea
        className={`bg-inherit outline-none w-full px-6 mob:px-5 rounded-none border-1 ${className} ${
          error ? "border-[#FFDCDB] bg-main-lorange" : "border-main-grayb"
        }`}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        required={required}
        readOnly={readOnly}
        onChange={onChange}
      />
      {error && <Error error={error} />}
    </div>
  );
};

export default InputField;
