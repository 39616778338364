import React from "react";
const Error = ({ error }) => {
  return (
    <div className="text-red-500 absolute top-0 right-0 h-full w-2/3  pointer-events-none flex flex-row gap-2 justify-end items-center pe-6 mob:pe-5 mob:w-full ">
      <div className="border-1 border-[#FFDCDB] p-2 rounded-4 text-f12 -tracking-3 leading-105 mob:absolute right-1.5 mob:h-auto mob:-top-5 bg-main-white">
        {error}
      </div>
      <svg
        className="w-7 mob:w-6"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 12.8333V18.6667M14 24.5C8.20101 24.5 3.5 19.799 3.5 14C3.5 8.20101 8.20101 3.5 14 3.5C19.799 3.5 24.5 8.20101 24.5 14C24.5 19.799 19.799 24.5 14 24.5ZM14.0581 9.33333V9.45L13.9419 9.45023V9.33333H14.0581Z"
          stroke="#FF5D58"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default Error;
