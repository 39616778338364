import React from "react";

const Footer = () => {
  return (
    <footer className="bg-main-dblue flex flex-row justify-between px-20 py-15 mob:justify-center mob:flex-col mob:items-center mob:px-10 mob:py-16 mob:gap-22.5">
      <div className="flex flex-col justify-between mob:contents">
        <a className="mob:w-full " href="/">
          <img
            src="/images/logowhite.svg"
            className="w-[25.75rem] mob:w-full mob:order-1"
            alt="logo"
          />
        </a>

        <a
          href="/policy"
          className="mob:order-3 text-main-white/50 leading-115 -tracking-2 font-semibold text-f14  uppercase hover:text-main-white transition-all"
        >
          Политика конфиденциальности
        </a>
      </div>

      <div className=" flex flex-col justify-between mob:order-2 mob:items-center ">
        <div className="leading-115 text-main-white/50 -tracking-2 text-f28 font-Montserrat font-semibold mb-[4.25rem] mob:mb-8">
          Контакты
        </div>
        <div className="text-main-white flex flex-col gap-4.5">
          <a
            href="tel:+79586107831"
            className="block text-f28 font-semibold leading-115 -tracking-2"
          >
            +7 958 610 78 31
          </a>
          <a
            href="tel:+79586107863"
            className="block text-f28 font-semibold leading-115 -tracking-2"
          >
            +7 958 610 78 63
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
