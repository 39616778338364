import React, { useRef, useEffect } from "react";
import Error from "./Error";

const CustomSelect = ({
  selectedValue,
  options,
  error,
  showDropdown,
  onOptionClick,
  onFocus,
  onBlur,
  placeholder,
  className = "",
}) => {
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        onBlur();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onBlur]);

  return (
    <div className={`w-full ${className}`}>
      <div className="relative">
        <div
          className={`flex w-full border-1  ${
            error
              ? "border-[#FFDCDB] bg-main-lorange"
              : "border-main-grayb bg-main-whitebg"
          }`}
          onClick={onFocus}
          ref={inputRef}
        >
          <input
            className="bg-inherit rounded-none placeholder:text-main-dblue outline-none w-full px-6 py-4 mob:px-5 cursor-pointer"
            type="text"
            value={selectedValue}
            placeholder={placeholder}
            readOnly
          />
          <svg
            className={`w-2.5 mx-7 mob:mx-5 mob:w-5 ${
              error ? "hidden" : "block"
            }`}
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1L5 5L1 1"
              stroke="#1E3352"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        {error && <Error error={error} />}
        {showDropdown && (
          <ul
            ref={dropdownRef}
            className="absolute left-0 w-full top-full z-10 bg-main-whitebg"
          >
            {options.length > 0 ? (
              options.map((option) => (
                <li
                  key={option.value}
                  onClick={() => onOptionClick(option)}
                  className="cursor-pointer group p-6 py-4 flex flex-row justify-between items-center border-1 border-main-whiteb bg-main-awhite transition-all hover:text-main-orange hover:bg-main-lorange hover:border-main-orangeb leading-105 -tracking-3 text-f14"
                >
                  {option.label}
                </li>
              ))
            ) : (
              <div className="p-6 py-4 border-1 border-main-whiteb bg-main-awhite leading-105 -tracking-3 text-f14">
                Нет доступных опций
              </div>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
