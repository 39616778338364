import React from "react";
import Form from "./components/form/Form";
const App = () => {
  return (
    <div className="px-[21.75rem] py-16 tab:px-[8.5rem] tab:py-12 mob:py-10 mob:pb-14 mob:px-5">
      <div className="p-12  border-1 border-main-grayb2 tab:px-[2.75rem] tab:pt-[3.25rem]  mob:px-0 mob:py-0 mob:border-0 ">
        <span className="block text-center leading-115 -tracking-2 text-main-gray2 text-f16  mob:text-f12 mb-12 mob:mb-8">
          {" "}
          Все поля обязательны для заполнения
        </span>
        <span className="text-f36 font-Montserrat text-main-redorange block mb-5  text-center leading-115 -tracking-2 font-semibold  mob:text-f28 mob:mb-4">
          Оставить заявку
        </span>
        <span className="block text-center px-32 leading-115 -tracking-2 text-f18 tab:mb-10 mob:text-f16 mb-12 mob:mb-12 mob:px-0">
          После заполнения формы с вами свяжутся и передадут дальнейшие
          инструкции
        </span>
        <Form />
      </div>
    </div>
  );
};

export default App;
