import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import HeaderHome from "./components/HeaderHome";
import HeaderForm from "./components/HeaderForm";
import Header from "./components/Header";
import Footer from "./components/Footer";

// Найдите все нужные элементы
const rootElement = document.getElementById("root");
const headerHomeElement = document.getElementById("headerhome");
const headerFormElement = document.getElementById("headerform");
const headerElement = document.getElementById("header");
const footerElement = document.getElementById("footer");

// Используйте createRoot для рендеринга каждого из них
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
}

if (headerHomeElement) {
  const headerRoot = createRoot(headerHomeElement);
  headerRoot.render(<HeaderHome />);
}
if (headerFormElement) {
  const headerRoot = createRoot(headerFormElement);
  headerRoot.render(<HeaderForm />);
}

if (headerElement) {
  const headerRoot = createRoot(headerElement);
  headerRoot.render(<Header />);
}

if (footerElement) {
  const footerRoot = createRoot(footerElement);
  footerRoot.render(<Footer />);
}
