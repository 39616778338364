import React from "react";

const Header = () => {
  return (
    <header className="bg-main-white flex flex-row justify-center items-center px-20 py-9  mob:justify-center mob:px-5 mob:py-6">
      <a
        href="/"
        className="flex flex-row justify-center uppercase items-center gap-1.5 text-f14 font-semibold leading-115 -tracking-2"
      >
        <svg
          className="w-3 min-w-3 mt-[0.0625rem]"
          viewBox="0 0 11 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.1666 5H0.833252M0.833252 5L4.83325 9M0.833252 5L4.83325 1"
            stroke="#1E3352"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span className="block"> назад </span>
      </a>
    </header>
  );
};

export default Header;
