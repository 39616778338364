import React from "react";

const Header = () => {
  return (
    <header className=" bg-main-dblue flex flex-row justify-between items-center px-20 py-8 tab:py-6 mob:justify-center mob:px-5 mob:py-5">
      <a className="mob:hidden" href="/">
        <img
          src="/images/logowhite.svg"
          className="w-[14.5625rem] mob:w-[16.5rem]"
          alt="logo"
        />
      </a>

      <div className="text-main-white flex flex-row justify-between items-center gap-11 mob:w-full mob:gap-4">
        <a
          href="tel:+79586107863"
          className="block text-f18 font-semibold leading-115 -tracking-2"
        >
          +7 958 610 78 63
        </a>
        <a
          href="tel:+79586107831"
          className="block text-f18 font-semibold leading-115 -tracking-2  mob:hidden"
        >
          +7 958 610 78 31
        </a>
        <a
          href="/form"
          className="block text-center text-f14 font-semibold leading-none bg-main-redorange rounded-full p-5 py-3.5 "
        >
          Оставить заявку
        </a>
      </div>
    </header>
  );
};

export default Header;
