export const organization = [
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization:
      'МАООУ санаторного типа для детей, нуждающихся в длительном лечении "Санаторно-лесная школа "Полянка"',
    FullEduОrganization:
      'Муниципальное автономное оздоровительное общеобразовательное учреждение санаторного типа для детей, нуждающихся в длительном лечении, Городского округа Балашиха "Санаторно-лесная школа "Полянка"',
    INN: 5001017380,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МАОУ "Гимназия №1"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение Городского округа Балашиха "Гимназия №1"',
    INN: 5012001706,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МАОУ "Гимназия №3"',
    FullEduОrganization:
      'муниципальное автономное общеобразовательное учреждение Городского округа Балашиха "Гимназия №3"',
    INN: 5001023095,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МАОУ "Земская гимназия"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ АВТОНОМНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ГОРОДСКОГО ОКРУГА БАЛАШИХА "ЗЕМСКАЯ ГИМНАЗИЯ"',
    INN: 5001054216,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МАОУ "Средняя общеобразовательная школа №14"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ АВТОНОМНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ГОРОДСКОГО ОКРУГА БАЛАШИХА "СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА №14"',
    INN: 5012075666,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МАОУ "Средняя общеобразовательная школа №15"',
    FullEduОrganization:
      'муниципальное автономное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа №15" ',
    INN: 5012085495,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МАОУ "Средняя общеобразовательная школа №20"',
    FullEduОrganization:
      "муниципальное автономное общеобразовательное учреждение Городского округа Балашиха «Средняя общеобразовательная школа № 20» ",
    INN: 5001104650,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization:
      'МАОУ "Средняя общеобразовательная школа №21 им. генерал-лейтенанта Д.А. Наливалкина"',
    FullEduОrganization:
      'муниципальное автономное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа № 21 имени генерал-лейтенанта Д.А. Наливалкина"',
    INN: 5001104668,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МАОУ "Средняя общеобразовательная школа №26"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа № 26 имени В.Н. Никишина"',
    INN: 5001105630,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МАОУ "Средняя общеобразовательная школа №33"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа №33" ',
    INN: 5001121141,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization:
      'МАОУ "Средняя общеобразовательная школа №3 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'муниципальное автономное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа №3 с углубленным изучением отдельных предметов"',
    INN: 5012005940,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МАОУ "Средняя общеобразовательная школа №5"',
    FullEduОrganization:
      'муниципальное автономное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа № 5" ',
    INN: 5012003573,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization:
      'МАОУ "Средняя общеобразовательная школа №7 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа №7 с углубленным изучением отдельных предметов"',
    INN: 5012003358,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Cредняя общеобразовательная школа №30"',
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха «Средняя общеобразовательная школа №30»",
    INN: 5001096382,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Гимназия №11"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Гимназия №11"',
    INN: 5012007458,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Гимназия №19"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Гимназия № 19" ',
    INN: 5001064366,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization:
      'МБОУ "Гимназия №1 им. Героя Российской Федерации А.В. Баландина"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Гимназия №1 имени Героя Российской Федерации А.В. Баландина"',
    INN: 5001022630,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Гимназия №2 им. М. Грачева"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Гимназия №2 имени М. Грачева" ',
    INN: 5012002682,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization:
      'МБОУ "Гимназия №9 им. дважды Героя Советского Союза Адмирала флота Советского Союза С.Г. Горшкова"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ГОРОДСКОГО ОКРУГА БАЛАШИХА "ГИМНАЗИЯ №9 ИМЕНИ ДВАЖДЫ ГЕРОЯ СОВЕТСКОГО СОЮЗА АДМИРАЛА ФЛОТА СОВЕТСКОГО СОЮЗА С.Г.ГОРШКОВА" ',
    INN: 5012008028,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Лицей"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Лицей" ',
    INN: 5012008010,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Салтыковская гимназия"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Салтыковская гимназия',
    INN: 5001023169,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №10"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа № 10" ',
    INN: 5012002160,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №11 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского  округа Балашиха «Средняя общеобразовательная школа № 11 с  углубленным изучением отдельных предметов"',
    INN: 5001023137,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №12"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа №12"  ',
    INN: 5012005901,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №1"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа №1"',
    INN: 5001023049,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №15"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа № 15 имени Героя Российской Федерации А.В. Буриличева"',
    INN: 5001023176,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №16 имени Героя Советского Союза Сережникова А.И."',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха «Средняя общеобразовательная школа №16 имени Героя Советского Союза Сережникова А.И.»",
    INN: 5001023183,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №18"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ГОРОДСКОГО ОКРУГА БАЛАШИХА "СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА №18"',
    INN: 5001023200,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №22"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа №22" ',
    INN: 5001023232,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №25 имени Героя Российской Федерации А.С. Ситникова"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ГОРОДСКОГО ОКРУГА БАЛАШИХА "СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА №25 ИМЕНИ ГЕРОЯ РОССИЙСКОЙ ФЕДЕРАЦИИ А.С. СИТНИКОВА"',
    INN: 5001023264,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №27"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа №27"  ',
    INN: 5001023271,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №29"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа № 29"',
    INN: 5001064260,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №2 имени Героя Российской Федерации А.В. Крестьянинова"',
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха «Средняя общеобразовательная школа № 2 имени Героя Российской Федерации А.В. Крестьянинова» ",
    INN: 5001023056,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №32"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа №32" ',
    INN: 5001023088,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №3 имени И.А. Флерова"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа №3 имени И.А. Флерова"',
    INN: 5001023063,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №4"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Средняя общеобразовательная школа № 4"',
    INN: 5012004961,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №6"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха " Средняя общебразовательная школа №6"',
    INN: 5012004591,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №8"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение  Городского округа Балашиха «Средняя общеобразовательная школа № 8»",
    INN: 5012005926,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization:
      'МБОУ "Школа №8 для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение Городского округа Балашиха "Школа №8 для обучающихся с ограниченными возможностями здоровья"',
    INN: 5001023105,
  },
  {
    Region: "Московская область",
    Municipality: "Балашиха городской округ",
    EduОrganization:
      'МКОУ "Школа для учащихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение  Городского округа Балашиха «Школа для учащихся с ограниченными возможностями здоровья»",
    INN: 5012004351,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization:
      'МБОУ "Глуховская школа-интернат №2" (основное общее образование)',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Глуховская школа-интернат №2"',
    INN: 5031024145,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization:
      'МБОУ "Ногинская специальная (коррекционная) школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Ногинская специальная (коррекционная) школа-интернат для обучающихся с ограниченными возможностями здоровья",
    INN: 5031001652,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization:
      'МБОУ "Образовательный центр "Аист" для обучающихся, воспитанников с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Образовательный центр "Аист" для обучающихся, воспитанников с ограниченными возможностями здоровья"',
    INN: 5031026287,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования "Богородский"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Центр образования "Богородский"',
    INN: 5031024674,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования "Старокупавинский лицей"',
    FullEduОrganization:
      'Муниципальное  бюджетное общеобразовательное учреждение "Центр образования "Старокупавинский лицей"',
    INN: 5031003762,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования №10"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования №10"',
    INN: 5031024233,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization:
      'МБОУ "Центр образования №12 им. Героя Советского Союза И.А. Маликова"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования №12 имени Героя Советского Союза И. А. Маликова"',
    INN: 5031024473,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования №17"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования№17"',
    INN: 5031024258,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization:
      'МБОУ "Центр образования №1 им. Героя России Андрея Завьялкина"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования № 1 имени Героя России Андрея Завьялкина"',
    INN: 5031024191,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования №21"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования №21"',
    INN: 5031024530,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования №23"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования № 23"',
    INN: 5031036493,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization:
      'МБОУ "Центр образования №25 им. Героя Советского Союза Михаила Васильевича Водопьянова"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования №25 имени Героя Советского Союза Михаила Васильевича Водопьянова"',
    INN: 5031036503,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования №26"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение  "Центр образования № 26"',
    INN: 5031036856,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования №28"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования  № 28" ',
    INN: 5031064349,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования №29"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования № 29"',
    INN: 5031024410,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization:
      'МБОУ "Центр образования №2 им. Короленко В.Г. с углубленным изучением иностранного языка"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования № 2 имени Короленко В.Г. с углубленным изучением иностранного языка"',
    INN: 5031026216,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization:
      'МБОУ "Центр образования №30 им. дважды Героя Советского Союза Главного Маршала авиации Колдунова А.И."',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования № 30 имени дважды Героя Советского Союза Главного Маршала авиации Колдунова Александра Ивановича"',
    INN: 5031028076,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования №33"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования №33"',
    INN: 5031027932,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования №3"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования № 3"',
    INN: 5031024226,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования №35"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования № 35"',
    INN: 5031036976,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования №4"',
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение центр образования №4",
    INN: 5031024561,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization:
      'МБОУ "Центр образования №45 с углубленным изучением иностранного языка"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования №45 с углубленным изучением иностранного языка"',
    INN: 5031027562,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization:
      'МБОУ "Центр образования №5 им. Героя России Максима Сураева"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования №5 имени Героя России Максима Сураева"',
    INN: 5031024113,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования №62"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования № 62"',
    INN: 5031053509,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования №8"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования №8"',
    INN: 5031024240,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization:
      'МБОУ "Центр образования №83 им. кавалера ордена Мужества Е.Е. Табакова и А.Н. Кощеева"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования № 83 имени кавалера ордена Мужества Е.Е.Табакова и А.Н.Кощеева"',
    INN: 5031026720,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования №9 им. Маршала Г.К. Жукова"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Центр образования № 9 им. Маршала Жукова Г.К."',
    INN: 5031024434,
  },
  {
    Region: "Московская область",
    Municipality: "Богородский городской округ",
    EduОrganization: 'МБОУ "Центр образования Купавинский"',
    FullEduОrganization:
      'Муниципальное бюджетное общеоразовательня организация "Центр образования Купавинский"',
    INN: 5031036447,
  },
  {
    Region: "Московская область",
    Municipality: "Бронницы городской округ",
    EduОrganization:
      'МАОУ "Гимназия имени Александра Александровича Пушкина" г.о. Бронницы',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение "Гимназия имени Александра Александровича Пушкина" городского округа Бронницы',
    INN: 5002003535,
  },
  {
    Region: "Московская область",
    Municipality: "Бронницы городской округ",
    EduОrganization: 'МАОУ "Лицей" г.о. Бронницы',
    FullEduОrganization:
      'муниципальное автономное общеобразовательное учреждение "Лицей" городского округа Бронницы',
    INN: 5002003542,
  },
  {
    Region: "Московская область",
    Municipality: "Бронницы городской округ",
    EduОrganization:
      'МАОУ "Средняя общеобразовательная школа №2 с углубленным изучением отдельных предметов имени Героя Советского Союза Н.А. Тимофеева"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение "Средняя общеобразовательная школа №2 с углубленным изучением отдельных предметов имени Героя Советского Союза Н.А. Тимофеева" городского округа Бронницы',
    INN: 5002003550,
  },
  {
    Region: "Московская область",
    Municipality: "Власиха городской округ",
    EduОrganization: 'МОУ Средняя общеобразовательная школа "Перспектива"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение средняя общеобразовательная школа "Перспектива" городского округа Власиха Московской области ',
    INN: 5032178934,
  },
  {
    Region: "Московская область",
    Municipality: "Власиха городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа им. А.С. Попова",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа имени А.С. Попова городского округа Власиха Московской области ",
    INN: 5032036545,
  },
  {
    Region: "Московская область",
    Municipality: "Волоколамский городской округ",
    EduОrganization: 'МОУ "Волоколамская средняя общеобразовательная школа №2"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Волоколамская средняя общеобразовательная школа № 2" ',
    INN: 5004011563,
  },
  {
    Region: "Московская область",
    Municipality: "Волоколамский городской округ",
    EduОrganization: 'МОУ "Волоколамская средняя общеобразовательная школа №3"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Волоколамская средняя общеобразовательная школа №3"',
    INN: 5004010841,
  },
  {
    Region: "Московская область",
    Municipality: "Волоколамский городской округ",
    EduОrganization:
      'МОУ "Волоколамская школа-интернат основного общего образования"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Волоколамская школа-интернат основного общего образования"',
    INN: 5004010721,
  },
  {
    Region: "Московская область",
    Municipality: "Волоколамский городской округ",
    EduОrganization: 'МОУ "Гимназия №1"',
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение «Гимназия №1 г. Волоколамска»",
    INN: 5004010859,
  },
  {
    Region: "Московская область",
    Municipality: "Волоколамский городской округ",
    EduОrganization: 'МОУ "Детгородковская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение " Детгородковская средняя общеобразовательная школа"',
    INN: 5004001653,
  },
  {
    Region: "Московская область",
    Municipality: "Волоколамский городской округ",
    EduОrganization: 'МОУ "Осташевская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Осташевска средняя общеобразовательная школа"',
    INN: 5004010986,
  },
  {
    Region: "Московская область",
    Municipality: "Волоколамский городской округ",
    EduОrganization: 'МОУ "Привокзальная средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Привокзальная средняя общеобразовательная школа"',
    INN: 5004010915,
  },
  {
    Region: "Московская область",
    Municipality: "Волоколамский городской округ",
    EduОrganization: 'МОУ "Спасская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Спасская средняя общеобразовательная школа" ',
    INN: 5004010908,
  },
  {
    Region: "Московская область",
    Municipality: "Волоколамский городской округ",
    EduОrganization: 'МОУ "Сычевская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Сычевская средняя общеобразовательная школа" ',
    INN: 5004011620,
  },
  {
    Region: "Московская область",
    Municipality: "Волоколамский городской округ",
    EduОrganization: 'МОУ "Ярополецкая средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Ярополецкая средняя общеобразовательная школа"',
    INN: 5004010873,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МБОУ - лицей "Воскресенская кадетская школа"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ - ЛИЦЕЙ "ВОСКРЕСЕНСКАЯ КАДЕТСКАЯ ШКОЛА" ',
    INN: 5005029034,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Виноградовская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение " Виноградовская средняя общеобразовательная школа"',
    INN: 5005029002,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Гимназия №1"',
    FullEduОrganization:
      'муниципальное общеобразовательное учреждение "Гимназия №1"',
    INN: 5005019614,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Лицей №23"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей №23"',
    INN: 5005028746,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Лицей №6"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей № 6"',
    INN: 5005020031,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Лицей им. Героя Советского Союза Стрельцова П.В."',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей имени Героя Советского Союза Стрельцова Павла Васильевича"',
    INN: 5005028898,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Москворецкая гимназия"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение  "Москворецкая гимназия"',
    INN: 5005029041,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа "Вектор"',
    FullEduОrganization:
      'муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа "Вектор"',
    INN: 5005029443,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа "Горизонт"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа "Горизонт"',
    INN: 5005028961,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа "Наши традиции"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа "Наши традиции" ',
    INN: 5005029147,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization:
      'МОУ "Средняя общеобразовательная школа "Траектория успеха"',
    FullEduОrganization:
      'муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа "Траектория успеха"',
    INN: 5005028947,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №13"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учрежение "Средняя общеобразовательная школа №13"',
    INN: 5005029066,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №17"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №17"',
    INN: 5005029436,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №2"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №2"',
    INN: 5005028721,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №3"',
    FullEduОrganization:
      "муниципальное общеобразовательное учреждение «Средняя общеобразовательная школа №3» ",
    INN: 5005028707,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №5"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "средняя общеобразовательная школа № 5"',
    INN: 5005029115,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №9"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение " Средняя общеобразовательная школа № 9"',
    INN: 5005028739,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №99"',
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Средняя общеобразовательная школа № 99",
    INN: 5005001769,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization:
      'МОУ "Средняя общеобразовательная школа им. Героя России летчика-испытателя Сергея Рыбникова"',
    FullEduОrganization:
      'муниципальное общеобразовательное учреждение " Cредняя общеобразовательная школа имени Героя России летчика - испытателя Сергея Рыбникова"  ',
    INN: 5005029235,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization: 'МОУ "Фаустовская средняя общеобразовательная школа"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ "ФАУСТОВСКАЯ СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА"',
    INN: 5005028930,
  },
  {
    Region: "Московская область",
    Municipality: "Воскресенск городской округ",
    EduОrganization:
      'МОУ "Хорловская школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Хорловская школа-интернат для обучающихся с ограниченными возможностями здоровья городского округа Воскресенск Московской области"',
    INN: 5005030015,
  },
  {
    Region: "Московская область",
    Municipality: "Восход городской округ",
    EduОrganization:
      "МОУ средняя общеобразовательная школа городского округа Восход",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа городского округа Восход Московской области",
    INN: 5017051210,
  },
  {
    Region: "Московская область",
    Municipality: "Дзержинский городской округ",
    EduОrganization: 'МБОУ "Гимназия №4"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Гимназия №4"',
    INN: 5027043716,
  },
  {
    Region: "Московская область",
    Municipality: "Дзержинский городской округ",
    EduОrganization: 'МБОУ "Гимназия №5"',
    FullEduОrganization:
      ' Муниципальное бюджетное общеобразовательное учреждение "Гимназия № 5"',
    INN: 5027045329,
  },
  {
    Region: "Московская область",
    Municipality: "Дзержинский городской округ",
    EduОrganization:
      'МБОУ "Лицей №3" им. Главного маршала авиации А.Е. Голованова',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Лицей №3" им. Главного маршала авиации А.Е. Голованова',
    INN: 5027012411,
  },
  {
    Region: "Московская область",
    Municipality: "Дзержинский городской округ",
    EduОrganization: 'МБОУ "Лицей №6 "Парус"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Лицей № 6 "Парус"',
    INN: 5027108258,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization:
      'МАОУ "Дмитровская общеобразовательная школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение "Дмитровская общеобразовательная школа-интернат для обучающихся с ограниченными возможностями здоровья Дмитровского городского округа Московской области"',
    INN: 5007029093,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization:
      'МАОУ "Яхромская общеобразовательная школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение "Яхромская общеобразовательная школа - интернат для обучающихся с ограниченными возможностями здоровья Дмитровского городского округа Московской области"  ',
    INN: 5007010649,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: 'МОУ "гимназия "Дмитров"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "гимназия "Дмитров""',
    INN: 5007016633,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: 'МОУ "Дмитровская гимназия "Логос"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Дмитровская гимназия"Логос"  ',
    INN: 5007010367,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: 'МОУ "Лицей №4 г. Дмитрова"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей №4 г. Дмитрова"',
    INN: 5007046363,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Внуковская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Внуковская средняя общеобразовательная школа",
    INN: 5007028639,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization:
      "МОУ Деденевская средняя общеобразовательная школа им. Н.К. Крупской",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Деденевская средняя общеобразовательная школа им.Н.К.Крупской",
    INN: 5007024137,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization:
      "МОУ Дмитровская средняя общеобразовательная школа №10 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Дмитровская средняя общеобразовательная школа №10 с углубленным изучением отдельных предметов",
    INN: 5007024144,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization:
      "МОУ Дмитровская средняя общеобразовательная школа №1 им. В.И. Кузнецова",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Дмитровская средняя общеобразовательная школа № 1 им. В.И. Кузнецова  ",
    INN: 5007010127,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Дмитровская средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Дмитровская средняя общеобразовательная школа №2  ",
    INN: 5007027508,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization:
      "МОУ Дмитровская средняя общеобразовательная школа №3 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Дмитровская средняя общеобразовательная школа №3 с углубленным изучением отдельных предметов",
    INN: 5007033406,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization:
      "МОУ Дмитровская средняя общеобразовательная школа №5 имени Героя Советского Союза К.А. Аверьянова",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Дмитровская прогимназия №5 имени Героя Советского Союза К.А. Аверьянова",
    INN: 5007023165,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Дмитровская средняя общеобразовательная школа №8",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Дмитровская средняя общеобразовательная школа №8",
    INN: 5007028808,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Дмитровская средняя общеобразовательная школа №9",
    FullEduОrganization:
      "муниципальное общеобразовательное учреждение Дмитровская средняя общеобразовательная школа № 9",
    INN: 5007024063,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Икшинская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Икшинская средняя общеобразовательная школа ",
    INN: 5007025187,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Каменская средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Каменская средняя общеобразовательная школа №2",
    INN: 5007028780,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Катуаровская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Катуаровская средняя общеобразовательная школа",
    INN: 5007029181,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Орудьевская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Орудьевская средняя общеобразовательная школа",
    INN: 5007029368,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Останкинская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Останкинская средняя общеобразовательная школа",
    INN: 5007029174,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Подосинковская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Подосинковская средняя общеобразовательная школа",
    INN: 5007055110,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Рогачевская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Рогачевская средняя общеобразовательная школа",
    INN: 5007028621,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Рыбненская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Рыбненская средняя общеобразовательная школа",
    INN: 5007029061,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Синьковская средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное  общеобразовательное учреждение Синьковская средняя общеобразовательная школа №1",
    INN: 5007027804,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Синьковская средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Синьковская средняя общеобразовательная школа №2 ",
    INN: 5007000520,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Черновская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Черновская средняя общеобразовательная школа ",
    INN: 5007029142,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Яхромская средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Яхромская средняя общеобразовательная школа №1",
    INN: 5007029135,
  },
  {
    Region: "Московская область",
    Municipality: "Дмитровский городской округ",
    EduОrganization: "МОУ Яхромская средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Яхромская средняя общеобразовательная школа №2",
    INN: 5007028607,
  },
  {
    Region: "Московская область",
    Municipality: "Долгопрудный городской округ",
    EduОrganization: "МАОУ Гимназия №13",
    FullEduОrganization:
      " Муниципальное автономное общеобразовательное учреждение городского округа Долгопрудный гимназия №13",
    INN: 5008003154,
  },
  {
    Region: "Московская область",
    Municipality: "Долгопрудный городской округ",
    EduОrganization: "МАОУ средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение городского округа Долгопрудный средняя общеобразовательная школа № 1",
    INN: 5008029459,
  },
  {
    Region: "Московская область",
    Municipality: "Долгопрудный городской округ",
    EduОrganization: "МАОУ средняя общеобразовательная школа №10",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение городского округа Долгопрудный средняя общеобразовательная школа №10",
    INN: 5008029522,
  },
  {
    Region: "Московская область",
    Municipality: "Долгопрудный городской округ",
    EduОrganization: "МАОУ средняя общеобразовательная школа №11",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение городского округа Долгопрудный средняя общеобразовательная школа № 11",
    INN: 5008019362,
  },
  {
    Region: "Московская область",
    Municipality: "Долгопрудный городской округ",
    EduОrganization: "МАОУ средняя общеобразовательная школа №14",
    FullEduОrganization:
      "муниципальное автономное общеобразовательное учреждение городского округа Долгопрудный средняя общеобразовательная школа №14 ",
    INN: 5008044880,
  },
  {
    Region: "Московская область",
    Municipality: "Долгопрудный городской округ",
    EduОrganization: "МАОУ Средняя общеобразовательная школа №16",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение городского округа Долгопрудный средняя общеобразовательная школа № 16",
    INN: 5047181440,
  },
  {
    Region: "Московская область",
    Municipality: "Долгопрудный городской округ",
    EduОrganization: "МАОУ средняя общеобразовательная школа №17",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение городского округа Долгопрудный средняя общеобразовательная школа № 17 ",
    INN: 5047224421,
  },
  {
    Region: "Московская область",
    Municipality: "Долгопрудный городской округ",
    EduОrganization:
      "МАОУ средняя общеобразовательная школа №6 им. Героя Советского Союза Н.Ф. Гастелло",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение городского округа Долгопрудный средняя общеобразовательная школа № 6 имени Героя Советского Союза Н.Ф. Гастелло",
    INN: 5008029441,
  },
  {
    Region: "Московская область",
    Municipality: "Долгопрудный городской округ",
    EduОrganization: "МАОУ средняя общеобразовательная школа №9",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение городского округа Долгопрудный средняя общеобразовательная школа № 9",
    INN: 5008029515,
  },
  {
    Region: "Московская область",
    Municipality: "Долгопрудный городской округ",
    EduОrganization: "МАОУ физико-математический лицей №5",
    FullEduОrganization:
      "Муниципольное автономное общеобразовательное учреждение городского округа Долгопрудный физико-математический лицей № 5",
    INN: 5008029473,
  },
  {
    Region: "Московская область",
    Municipality: "Долгопрудный городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №7",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №7 городского округа Долгопрудный ",
    INN: 5008029498,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization: "МАОУ Барыбинская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Барыбинская средняя общеобразовательная школа",
    INN: 5009035173,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization: "МАОУ Белостолбовская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Белостолбовская средняя общеобразовательная школа",
    INN: 5009035568,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization: "МАОУ Востряковский лицей №1",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Востряковский лицей №1 ",
    INN: 5009034797,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization: "МАОУ Домодедовская Гимназия №5",
    FullEduОrganization:
      "муниципальное автономное общеобразовательной учреждение Домодедовская гимназия №5",
    INN: 5009034780,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization: "МАОУ Домодедовская средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Домодедовская средняя общеобразовательная школа №1",
    INN: 5009034772,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization:
      "МАОУ Домодедовская средняя общеобразовательная школа №12 имени полного кавалера ордена Славы В.Д. Преснова",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Домодедовская средняя общеобразовательная школа №12 имени полного кавалера ордена Славы В.Д. Преснова",
    INN: 5009120446,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization:
      "МАОУ Домодедовская средняя общеобразовательная школа №2 им. М.Д. Глазова",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Домодедовская средняя общеобразовательная школа №2  имени М.Д. Глазова",
    INN: 5009034765,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization:
      "МАОУ Домодедовская средняя общеобразовательная школа №4 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Домодедовская средняя общеобразовательная школа 4 с углубленным изучением отдельных предметов",
    INN: 5009035945,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization:
      "МАОУ Домодедовская средняя общеобразовательная школа №7 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Домодедовская средняя школа № 7 с углубленным изучением отдельных предметов",
    INN: 5009034966,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization: "МАОУ Домодедовская средняя общеобразовательная школа №8",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Домодедовская средняя общеобразовательная школа №8",
    INN: 5009106057,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization:
      "МАОУ Домодедовская средняя общеобразовательная школа №9 им. Героя Советского Союза Д.К. Курыжова",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Домодедовская средняя общеобразовательная школа №9 имени Героя Советского Союза Д.К. Курыжова",
    INN: 5009096786,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization:
      "МАОУ Домодедовский лицей №3 им. Героя Советского Союза Ю.П. Максимова",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Домодедовский лицей №3 имени Героя Советского союза Ю.П. Максимова",
    INN: 5009035046,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization:
      "МАОУ Заревская средняя общеобразовательная школа с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Заревская средняя общеобразовательная школа с углубленным изучением отдельных предметов",
    INN: 5009036040,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization:
      "МАОУ Ильинская средняя общеобразовательная школа им. полного кавалера ордена Славы И.И. Сидорова",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Ильинская средняя общеобразовательная школа имени полного кавалера ордена Славы И.И. Сидорова ",
    INN: 5009034885,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization:
      "МАОУ Константиновская средняя общеобразовательная школа им. Героя Социалистического Труда Н.В. Хухрева",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Константиновская средняя общеобразовательная школа имени Героя Социалистического Труда Н.В. Хухрева",
    INN: 5009035448,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization: "МАОУ Краснопутьская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение  Краснопутьская средняя общеобразовательная школа ",
    INN: 5009034839,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization: "МАОУ Повадинская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Повадинская средняя общеобразовательная школа ",
    INN: 5009034980,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization: "МАОУ Ямская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Ямская средняя общеобразовательная школа  ",
    INN: 5009032567,
  },
  {
    Region: "Московская область",
    Municipality: "Домодедово городской округ",
    EduОrganization:
      "МБОУ Кутузовская школа-интернат для обучающихся с ограниченными возможностями здоровья",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Кутузовская школа-интернат для обучающихся с ограниченными возможностями здоровья",
    INN: 5009034910,
  },
  {
    Region: "Московская область",
    Municipality: "Дубна городской округ",
    EduОrganization: 'МБОУ "Гимназия №3"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Гимназия № 3 г. Дубны Московской области"',
    INN: 5010011593,
  },
  {
    Region: "Московская область",
    Municipality: "Дубна городской округ",
    EduОrganization: 'МБОУ "Гуманитарно-эстетическая гимназия №11"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Гуманитарно-эстетическая гимназия № 11 г. Дубны Московской области" ',
    INN: 5010028043,
  },
  {
    Region: "Московская область",
    Municipality: "Дубна городской округ",
    EduОrganization: 'МБОУ "Лицей "Дубна"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Лицей "Дубна" г. Дубны Московской области" ',
    INN: 5010011280,
  },
  {
    Region: "Московская область",
    Municipality: "Дубна городской округ",
    EduОrganization:
      'МБОУ "Общеобразовательная школа "Возможность" для детей с ограниченными возможностями здоровья"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Общеобразовательная школа «Возможность»  для детей с ограниченными возможностями здоровья г.  Дубны Московской области»",
    INN: 5010029086,
  },
  {
    Region: "Московская область",
    Municipality: "Дубна городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №10"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №10 г. Дубны Московской области"',
    INN: 5010028036,
  },
  {
    Region: "Московская область",
    Municipality: "Дубна городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №1 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Средняя общеобразовательная школа № 1 с углубленным изучением отдельных предметов г.Дубны Московской области» ",
    INN: 5010028029,
  },
  {
    Region: "Московская область",
    Municipality: "Дубна городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №2"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 2 г.Дубны Московской области"',
    INN: 5010029897,
  },
  {
    Region: "Московская область",
    Municipality: "Дубна городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №5"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 5 г. Дубны Московской области"',
    INN: 5010028004,
  },
  {
    Region: "Московская область",
    Municipality: "Дубна городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №7 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №7 с углубленным изучением отдельных предметов г. Дубны Московской области"',
    INN: 5010009361,
  },
  {
    Region: "Московская область",
    Municipality: "Дубна городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №9 с углубленным изучением иностранных языков"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 9 с углубленным изучением иностранных языков г. Дубны Московской области"',
    INN: 5010028117,
  },
  {
    Region: "Московская область",
    Municipality: "Дубна городской округ",
    EduОrganization: "МБОУ Гимназия №8 им. академика Н.Н. Боголюбова",
    FullEduОrganization:
      " Муниципальное бюджетное общеобразовательное учреждение гимназия № 8 им.академика Н.Н.Боголюбова города Дубны Московской области   ",
    INN: 5010028100,
  },
  {
    Region: "Московская область",
    Municipality: "Дубна городской округ",
    EduОrganization: "МБОУ Лицей №6 им. академика Г.Н. Флерова",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение города Дубны Московской области, лицей №6 имени академика Г.Н.Флерова ",
    INN: 5010027931,
  },
  {
    Region: "Московская область",
    Municipality: "Егорьевск городской округ",
    EduОrganization: 'МАОУ "Центр образования"',
    FullEduОrganization: 'МАОУ "Центр образования"',
    INN: 5011018552,
  },
  {
    Region: "Московская область",
    Municipality: "Егорьевск городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №4",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное  учреждение средняя общеобразовательная школа № 4",
    INN: 5011001196,
  },
  {
    Region: "Московская область",
    Municipality: "Егорьевск городской округ",
    EduОrganization:
      'МКОУ "Клеменовская общеобразовательная школа-интернат для обучающихся, воспитанников с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное казенное общеобразовательное учреждение "Клеменовская общеобразовательная школа-интернат для обучающихся, воспитанников с ограниченными возможностями здоровья"',
    INN: 5011019764,
  },
  {
    Region: "Московская область",
    Municipality: "Егорьевск городской округ",
    EduОrganization:
      'МКОУ "Общеобразовательная школа №9 для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      "Муниципальное казенное общеобразовательное учреждение «Общеобразовательная школа № 9 для обучающихся с ограниченными возможностями здоровья»  ",
    INN: 5011019130,
  },
  {
    Region: "Московская область",
    Municipality: "Егорьевск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №13"',
    FullEduОrganization:
      'муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №13" ',
    INN: 5011019411,
  },
  {
    Region: "Московская область",
    Municipality: "Егорьевск городской округ",
    EduОrganization: "МОУ Георгиевская гимназия",
    FullEduОrganization: 'МАОУ "Георгиевская гимназия"',
    INN: 5011005850,
  },
  {
    Region: "Московская область",
    Municipality: "Егорьевск городской округ",
    EduОrganization: "МОУ Гимназия №10",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение гимназия №10",
    INN: 5011005955,
  },
  {
    Region: "Московская область",
    Municipality: "Егорьевск городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Средняя общеобразовательная школа №1",
    INN: 5011002224,
  },
  {
    Region: "Московская область",
    Municipality: "Егорьевск городской округ",
    EduОrganization:
      "МОУ средняя общеобразовательная школа №12 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 12 с углубленным изучением отдельных предметов ",
    INN: 5011005867,
  },
  {
    Region: "Московская область",
    Municipality: "Егорьевск городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №15",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 15",
    INN: 5011019316,
  },
  {
    Region: "Московская область",
    Municipality: "Егорьевск городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 2",
    INN: 5011018979,
  },
  {
    Region: "Московская область",
    Municipality: "Егорьевск городской округ",
    EduОrganization:
      "МОУ средняя общеобразовательная школа №3 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №3 с углубленным изучением отдельных предметов",
    INN: 5011002739,
  },
  {
    Region: "Московская область",
    Municipality: "Егорьевск городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №5",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 5",
    INN: 5011020128,
  },
  {
    Region: "Московская область",
    Municipality: "Егорьевск городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №8",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №8",
    INN: 5011022904,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization: "МБОУ Гимназия №1",
    FullEduОrganization:
      "Муниципальное бюджетное  общеобразовательное учреждение Гимназия №1 ",
    INN: 5013000381,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization: "МБОУ Лицей №14 имени М.М. Громова",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение лицей №14",
    INN: 5013029341,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization:
      "МБОУ общеобразовательная школа-интернат основного общего образования",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение общеобразовательная школа-интернат основного общего образования",
    INN: 5013029895,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization:
      "МБОУ - средняя общеобразовательная школа №10 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение-средняя общеобразовательная школа №10 с углублённым изучением отдельных предметов",
    INN: 5013029969,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №11",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №11",
    INN: 5013029905,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization:
      "МБОУ - средняя общеобразовательная школа №12 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение - средняя общеобразовательная школа №12 с углубленным изучением отдельных предметов",
    INN: 5013029831,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization:
      "МБОУ - средняя общеобразовательная школа №13 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение-средняя общеобразовательная школа №13 с углубленным изучением отдельных предметов ",
    INN: 5013029824,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа №15 с русским этнокультурным компонентом",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 15 с русским этнокультурным компонентом",
    INN: 5013054122,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа №2",
    INN: 5013029775,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа №3 с углубленным изучением английского языка",
    FullEduОrganization:
      "муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 3 с  углубленным изучением английского языка",
    INN: 5013029790,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа №5 им. Ю.А. Гарнаева  с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 5 им. Ю.А.Гарнаева с углубленным изучением отдельных предметов",
    INN: 5013029888,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization:
      "МБОУ - средняя общеобразовательная школа №6 с углубленным изучением предметов музыкально-эстетического цикла",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение - средняя общеобразовательная школа №6 с углубленным изучением предметов музыкально-эстетического цикла",
    INN: 5013029800,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа №8 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение - средняя общеобразовательная школа №8 с углубленным изучением отдельных предметов ",
    INN: 5013029334,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization: "МБОУ - средняя общеобразовательная школа №9",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение-средняя общеобразовательная школа №9",
    INN: 5013029863,
  },
  {
    Region: "Московская область",
    Municipality: "Жуковский городской округ",
    EduОrganization:
      "МОУ - средняя общеобразовательная школа №7 им. Героя Российской Федерации С.Н. Мельникова",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение - средняя общеобразовательная школа № 7 имени Героя Российской Федерации С.Н. Мельникова ",
    INN: 5013024103,
  },
  {
    Region: "Московская область",
    Municipality: "Зарайск городской округ",
    EduОrganization: 'МБОУ "Гимназия №2"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Гимназия №2"',
    INN: 5014007485,
  },
  {
    Region: "Московская область",
    Municipality: "Зарайск городской округ",
    EduОrganization: 'МБОУ "Лицей №5"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Лицей №5"',
    INN: 5014007478,
  },
  {
    Region: "Московская область",
    Municipality: "Зарайск городской округ",
    EduОrganization: 'МБОУ "Мендюкинская средняя школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение " Мендюкинская средняя школа"',
    INN: 5014007534,
  },
  {
    Region: "Московская область",
    Municipality: "Зарайск городской округ",
    EduОrganization:
      'МБОУ "Средняя школа №1 им. дважды Героя Советского Союза В.Н. Леонова"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя школа №1 имени дважды Героя Советского Союза В.Н. Леонова" ',
    INN: 5014007492,
  },
  {
    Region: "Московская область",
    Municipality: "Зарайск городской округ",
    EduОrganization: 'МБОУ "Средняя школа №6"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя школа №6"',
    INN: 5014007460,
  },
  {
    Region: "Московская область",
    Municipality: "Звездный городок городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа им. В.М. Комарова с углубленным изучением английского языка",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Средняя общеобразовательная школа имени В.М.Комарова с углублённым изучением английского языка ЗАТО городского округа Звёздный городок Московской области",
    INN: 5050032085,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization:
      'МБОУ "Дедовская школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Дедовская школа-интернат для обучающихся ограниченными возможностями здоровья"',
    INN: 5017049236,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization:
      'МБОУ "Новопетровская  школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Новопетровская школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    INN: 5017048440,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Бужаровская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Бужаровская средняя общеобразовательная школа"',
    INN: 5017048017,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Глебовская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Глебовская средняя общеобразовательная школа" ',
    INN: 5017048881,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Дедовская средняя общеобразовательная школа №1"',
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение «Дедовская средняя общеобразовательная школа № 1»",
    INN: 5017048930,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Дедовская средняя общеобразовательная школа №3"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Дедовская средняя общеобразовательная школа №3 имени дважды Героя Советского Союза А.П.Белобородова"',
    INN: 5017048948,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Ивановская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Ивановская средняя общеобразовательная школа"',
    INN: 5017048000,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Истринская средняя общеобразовательная школа №3"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Истринская средняя общеобразовательная школа № 3 имени М.Ю. Лермонтова"',
    INN: 5017045425,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Костровская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Костровская средняя общеобразовательная школа"',
    INN: 5017048031,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Лицей г. Дедовск"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей г. Дедовск"',
    INN: 5017027232,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Лицей г. Истра"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей г. Истра" ',
    INN: 5017027225,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Новопетровская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Новопетровская средняя общеобразовательная школа"',
    INN: 5017048345,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Октябрьская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Октябрьская средняя общеобразовательная школа"',
    INN: 5017048377,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Павловская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Павловская средняя общеобразовательная школа"',
    INN: 5017048899,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Первомайская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Первомайская средняя общеобразовательная школа"',
    INN: 5017049003,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Покровская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Покровская средняя общеобразовательная школа" ',
    INN: 5017048296,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Рождественская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Рождественская средняя общеобразовательная школа"',
    INN: 5017031101,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Румянцевская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Румянцевская средняя общеобразовательная школа"',
    INN: 5017048352,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №2" г. Истра',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа № 2" г. Истра ',
    INN: 5017049726,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization:
      'МОУ "Средняя общеобразовательная школа №4 им. Героя Советского Союза Л.М. Доватора"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №4 имени Героя Советского Союза Л.М.Доватора"',
    INN: 5017049853,
  },
  {
    Region: "Московская область",
    Municipality: "Истра городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа им. А.П. Чехова"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа имени А.П. Чехова"',
    INN: 5017045418,
  },
  {
    Region: "Московская область",
    Municipality: "Кашира городской округ",
    EduОrganization: 'МБОУ "Барабановская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Барабановская средняя общеобразовательная школа"  ',
    INN: 5019010957,
  },
  {
    Region: "Московская область",
    Municipality: "Кашира городской округ",
    EduОrganization: 'МБОУ "Богатищевская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Богатищевская средняя общеобразовательная школа"',
    INN: 5019010890,
  },
  {
    Region: "Московская область",
    Municipality: "Кашира городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №10"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Средняя общеобразовательная школа №10»",
    INN: 5019011566,
  },
  {
    Region: "Московская область",
    Municipality: "Кашира городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №1 имени Героя Советского Союза А.И. Выборнова"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 1 имени Героя Советского Союза А.И. Выборнова"',
    INN: 5019010812,
  },
  {
    Region: "Московская область",
    Municipality: "Кашира городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №3"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №3"',
    INN: 5019010795,
  },
  {
    Region: "Московская область",
    Municipality: "Кашира городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №4"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №4"',
    INN: 5019010851,
  },
  {
    Region: "Московская область",
    Municipality: "Кашира городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №9"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №9"',
    INN: 5019011020,
  },
  {
    Region: "Московская область",
    Municipality: "Кашира городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа-интернат"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа-интернат"  ',
    INN: 5019013242,
  },
  {
    Region: "Московская область",
    Municipality: "Кашира городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа с углубленным изучением отдельных предметов №2"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа с углубленным изучением отдельных предметов № 2"',
    INN: 5019010883,
  },
  {
    Region: "Московская область",
    Municipality: "Кашира городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа с углубленным изучением отдельных предметов №7"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Средняя общеобразовательная школа с углубленным изучением отдельных предметов № 7»  ",
    INN: 5019010918,
  },
  {
    Region: "Московская область",
    Municipality: "Кашира городской округ",
    EduОrganization: 'МБОУ "Тарасковская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Тарасковская средняя общеобразовательная школа"',
    INN: 5019011118,
  },
  {
    Region: "Московская область",
    Municipality: "Кашира городской округ",
    EduОrganization:
      'МКОУ "Каширская коррекционная общеобразовательная школа-интернат"',
    FullEduОrganization:
      'Муниципальное казенное общеобразовательное учреждение "Каширская коррекционная общеобразовательная школа-интернат"',
    INN: 5019012168,
  },
  {
    Region: "Московская область",
    Municipality: "Клин городской округ",
    EduОrganization: 'МОУ "Центр детства "Жемчужинка"',
    FullEduОrganization:
      'Муниципальное образовательное учреждение "Центр Детства "Жемчужинка"',
    INN: 5020010454,
  },
  {
    Region: "Московская область",
    Municipality: "Клин городской округ",
    EduОrganization: "МОУ - Гимназия №2",
    FullEduОrganization:
      "МУНИЦИПАЛЬНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ГИМНАЗИЯ ИМЕНИ ВЛАДИМИРА ИВАНОВИЧА ТАНЕЕВА",
    INN: 5020029409,
  },
  {
    Region: "Московская область",
    Municipality: "Клин городской округ",
    EduОrganization: "МОУ - гимназия имени В.Н. Татищева",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение - гимназия им. В.Н. Татищева",
    INN: 5020029303,
  },
  {
    Region: "Московская область",
    Municipality: "Клин городской округ",
    EduОrganization: "МОУ - гимназия им. К.Д. Ушинского",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение-гимназия им. К.Д. Ушинского",
    INN: 5020029350,
  },
  {
    Region: "Московская область",
    Municipality: "Клин городской округ",
    EduОrganization: "МОУ Лицей №10 им. Д.И. Менделеева",
    FullEduОrganization:
      "МУНИЦИПАЛЬНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ЛИЦЕЙ № 10 ИМЕНИ Д.И. МЕНДЕЛЕЕВА ",
    INN: 5020029381,
  },
  {
    Region: "Московская область",
    Municipality: "Клин городской округ",
    EduОrganization: 'МОУ - средняя общеобразовательная школа "Гармония"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение - средняя общеобразовательная школа "Гармония"',
    INN: 5020029335,
  },
  {
    Region: "Московская область",
    Municipality: "Клин городской округ",
    EduОrganization: 'МОУ - средняя общеобразовательная школа "Имена Победы"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение-средняя общеобразовательная школа "Имена Победы" ',
    INN: 5020029173,
  },
  {
    Region: "Московская область",
    Municipality: "Клин городской округ",
    EduОrganization: 'МОУ - средняя общеобразовательная школа "Развитие"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ - СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА "РАЗВИТИЕ"',
    INN: 5020029286,
  },
  {
    Region: "Московская область",
    Municipality: "Клин городской округ",
    EduОrganization: 'МОУ - средняя общеобразовательная школа "Содружество"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение - средняя общеобразовательная школа "Содружество"',
    INN: 5020029310,
  },
  {
    Region: "Московская область",
    Municipality: "Клин городской округ",
    EduОrganization:
      "МОУ - средняя общеобразовательная школа  им. В.В. Талалихина",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение - средняя общеобразовательная школа имени В.В. Талалихина",
    INN: 5020029328,
  },
  {
    Region: "Московская область",
    Municipality: "Клин городской округ",
    EduОrganization:
      "МОУ - средняя общеобразовательная школа имени Маргариты Калининой",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение - средняя общеобразовательная школа имени Маргариты Калининой",
    INN: 5020029342,
  },
  {
    Region: "Московская область",
    Municipality: "Клин городской округ",
    EduОrganization: 'МОУ - Школа "Планета детства"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение-школа "Планета детства"',
    INN: 5020071432,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: 'МБОУ "Гимназия №2 "Квантор"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Гимназия № 2 "Квантор" ',
    INN: 5022029700,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: 'МБОУ "Гимназия №8"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Гимназия №8" ',
    INN: 5022028224,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization:
      'МБОУ "Гимназия №9 имени дважды Героя Советского Союза С.Г. Горшкова"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Гимназия №9 имени дважды Героя Советского Союза С.Г. Горшкова"',
    INN: 5022028190,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: 'МБОУ "Лицей №4"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Лицей №4" Коломенского г.о.',
    INN: 5022028200,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization:
      "МБОУ Бояркинская средняя общеобразовательная школа им. М.Е. Катукова",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Бояркинская средняя общеобразовательная школа имени М.Е. Катукова",
    INN: 5033008276,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МБОУ Горская средняя общеобразовательная школа",
    FullEduОrganization:
      "МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ГОРСКАЯ СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА ",
    INN: 5033008290,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МБОУ Емельяновская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Емельяновская средняя общеобразовательная школа ",
    INN: 5033008212,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization:
      "МБОУ Коробчеевская школа-интернат для обучающихся, воспитанников с ограниченными возможностями здоровья",
    FullEduОrganization:
      'Муниципальное бюджетное образовательное учреждение "Коробчеевская школа для обучающихся с ограниченными возможностями здоровья"',
    INN: 5070010851,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МБОУ Полурядинская средняя общеобразовательная школа",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение Полурядинская средняя общеобразовательная школа",
    INN: 5033008389,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №12",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №12",
    INN: 5022030086,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №14",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 14  ",
    INN: 5022030093,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №15",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №15 ",
    INN: 5022030103,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №16",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №16 ",
    INN: 5022028249,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №17",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №17",
    INN: 5022030110,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учереждение средняя общеобразовательная школа № 2",
    INN: 5033008170,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №21",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 21",
    INN: 5022028591,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №3",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 3",
    INN: 5033008131,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №30",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №30",
    INN: 5022028810,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МБОУ Чернореченская основная общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Чернореченская основная общеобразовательная школа",
    INN: 5072713183,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization:
      "МБОУ Школа №19 для детей с ограниченными возможностями здоровья",
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Школа №19 для детей с ограниченными возможностями здоровья"',
    INN: 5022030135,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization:
      'МБОУ Школа для обучающихся с ограниченными возможностями здоровья "Надежда"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Школа для обучающихся с ограниченными возможностями здоровья "Надежда"',
    INN: 5022028827,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МОУ Маливская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Маливская средняя общеобразовательная школа",
    INN: 5070010756,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МОУ Непецинская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Непецинская средняя общеобразовательная школа",
    INN: 5070010837,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization:
      "МОУ Пановская средняя общеобразовательная школа им. Героя Советского Союза П.Л. Черябкина",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Пановская средняя общеобразовательная школа имени Героя Советского Союза П.Л.Черябкина ",
    INN: 5070010594,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МОУ Песковская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Песковская средняя общеобразовательная школа",
    INN: 5070010570,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization:
      "МОУ Проводниковская основная общеобразовательная школа имени Героя Российской Федерации А.С. Маслова",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Проводниковская основная общеобразовательная школа имени Героя Российской Федерации А.С. Маслова",
    INN: 5070010675,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МОУ Радужненская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Радужненская средняя общеобразовательная школа",
    INN: 5070010883,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization:
      "МОУ Сергиевская средняя общеобразовательная школа имени почетного гражданина Московской области М.С. Трифонова",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Сергиевская средняя общеобразовательная школа имени почетного гражданина Московской области М.С. Трифонова",
    INN: 5070010763,
  },
  {
    Region: "Московская область",
    Municipality: "Коломна городской округ",
    EduОrganization: "МОУ Сосново-Борская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Сосново-Борская средняя общеобразовательная школа",
    INN: 5070010611,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МАОУ городского округа Королев Московской области "Гимназия "Российская школа"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение городского округа Королёв Московской области "Гимназия "Российская школа"',
    INN: 5018020751,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МАОУ городского округа Королев Московской области "Гимназия №9"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение городского округа Королев Московской области "Гимназия №9"',
    INN: 5018044865,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МАОУ городского округа Королев Московской области "Лицей №19"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение городского округа Королёв Московской области "Лицей № 19" ',
    INN: 5018045065,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Гимназия №11 с углубленным изучением иностранных языков"',
    FullEduОrganization:
      "МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ГОРОДСКОГО ОКРУГА КОРОЛЁВ МОСКОВСКОЙ ОБЛАСТИ «ГИМНАЗИЯ №11 С ИЗУЧЕНИЕМ ИНОСТРАННЫХ ЯЗЫКОВ»",
    INN: 5018045019,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Гимназия №17"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Московской области "Гимназия № 17"',
    INN: 5018044921,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Гимназия №18 им. И.Я. Илюшина"',
    FullEduОrganization:
      'Муниципальное бюджетное образовательное учреждение городского округа Королёв Московской области "Гимназия №18 имени И.Я. Илюшина"',
    INN: 5018047288,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Гимназия №3 им. Л.П. Данилиной"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Московской области "Гимназия № 3 имени Л.П. Данилиной" ',
    INN: 5054009256,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Гимназия №5"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Московской области "Гимназия №5" ',
    INN: 5054009263,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Лицей №4"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Московской области "Лицей № 4"',
    INN: 5054009288,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Лицей №5"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Московской области "Лицей № 5" ',
    INN: 5018195293,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Первомайская средняя общеобразовательная школа №2 им. М.Ф. Тихонова"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Московской области "Первомайская средняя общеобразовательная школа № 2 им. М.Ф. Тихонова" ',
    INN: 5018048370,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Средняя общеобразовательная школа №10"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Московской области "Средняя общеобразовательная школа № 10"',
    INN: 5018045072,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Средняя общеобразовательная школа №12"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Московской области "Средняя общеобразовательная школа № 12"',
    INN: 5018044801,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Средняя общеобразовательная школа №13"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Московской области «Средняя общеобразовательная школа№13»",
    INN: 5018044978,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Средняя общеобразовательная школа №1"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Московской области "Средняя общеобразовательная школа № 1"',
    INN: 5018044833,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Средняя общеобразовательная школа №15 им. Б.Н. Флёрова"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Мосовской области "Средняя общеобразовательная школа № 15 имени Б.Н.Флёрова""',
    INN: 5018044431,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Средняя общеобразовательная школа №20"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв  Московской области "Средняя общеобразовательная школа № 20"',
    INN: 5018047947,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Средняя общеобразовательная школа №22"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ГОРОДСКОГО ОКРУГА КОРОЛЕВ МОСКОВСКОЙ ОБЛАСТИ "СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА № 22"  ',
    INN: 5018045058,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Средняя общеобразовательная школа №2 им. В.Н. Михайлова"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Московской области "Средняя общеобразовательная школа №2 имени В.Н. Михайлова"',
    INN: 5018044777,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Средняя общеобразовательная школа №3"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Московской области "Средняя общеобразовательная школа № 3" ',
    INN: 5018045121,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Средняя общеобразовательная школа №5"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Московской области "Средняя общеобразовательная школа № 5"',
    INN: 5018044784,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МБОУ городского округа Королев Московской области "Школа-интернат для слепых и слабовидящих детей"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение городского округа Королёв Московской области "Школа-интернат для слепых и слабовидящих детей"',
    INN: 5018044960,
  },
  {
    Region: "Московская область",
    Municipality: "Королев городской округ",
    EduОrganization:
      'МКОУ городского округа Королев Московской области "Школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      "Муниципальное  казенное  общеобразовательное учреждение городского округа Королев Московской области «Школа-интернат для обучающихся с ограниченными возможностями здоровья»",
    INN: 5018045890,
  },
  {
    Region: "Московская область",
    Municipality: "Котельники городской округ",
    EduОrganization:
      'МБОУ "Котельниковская средняя общеобразовательная школа №1 им. Героя Советского Союза Л.Д.Чурилова"',
    FullEduОrganization:
      'Муниципальное бюджетное образовательное учреждение "Котельниковская средняя общеобразовательная школа №1имени Героя Советского Союза Л.Д.Чурилова"',
    INN: 5027017762,
  },
  {
    Region: "Московская область",
    Municipality: "Котельники городской округ",
    EduОrganization:
      'МБОУ "Котельниковская средняя общеобразовательная школа №2"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Котельниковская средняя общеобразовательная школа №2"',
    INN: 5027057155,
  },
  {
    Region: "Московская область",
    Municipality: "Котельники городской округ",
    EduОrganization:
      'МБОУ "Котельниковская средняя общеобразовательная школа №3"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение  «Котельниковская средняя общеобразовательная школа №3»",
    INN: 5027059226,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: 'МБОУ "Нахабинская гимназия №4"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Нахабинская гимназия №4"',
    INN: 5024044743,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: 'МБОУ "Начальная школа-детский сад"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Начальная школа-детский сад"',
    INN: 5024033220,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: 'МБОУ "Образовательный центр "Вершина"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Образовательный центр "Вершина""',
    INN: 5024204108,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ Ангеловская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Ангеловская средняя общеобразовательная школа ",
    INN: 5024028808,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization:
      "МБОУ Архангельская средняя общеобразовательная школа им. А.Н. Косыгина",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Архангельская средняя общеобразовательная школа имени А.Н.Косыгина  ",
    INN: 5024028734,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ Гимназия №2",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение гимназия № 2",
    INN: 5024028660,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ Гимназия №5",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение гимназия №5 ",
    INN: 5024028773,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ Гимназия №6",
    FullEduОrganization:
      "МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ГИМНАЗИЯ №6",
    INN: 5024028886,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ Гимназия №7 им. Д.П. Яковлева",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение гимназия № 7 имени Д.П. Яковлева",
    INN: 5024028879,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ Ильинская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Ильинская средняя общеобразовательная школа ",
    INN: 5024028815,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ Лицей №1 п. Нахабино",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение лицей №1 п.Нахабино",
    INN: 5024028798,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ Лицей №4",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение лицей №4",
    INN: 5024028685,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ Нахабинская средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Нахабинская средняя общеобразовательная школа №2» ",
    INN: 5024028780,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization:
      "МБОУ Нахабинская средняя общеобразовательная школа №3 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Нахабинская средняя общеобразовательная школа №3 с углубленным изучением отдельных предметов",
    INN: 5024028741,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ начальная общеобразовательная школа №17",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение начальная общеобразовательная школа № 17",
    INN: 5024130431,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization:
      "МБОУ Николо-Урюпинская основная общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Николо-Урюпинская основная общеобразовательная школа  ",
    INN: 5024028822,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: 'МБОУ Образовательный центр "Созвездие" (для детей с ОВЗ)',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Образовательный центр "Созвездие""',
    INN: 5024076223,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ Опалиховская Гимназия",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Опалиховская гимназия",
    INN: 5024027755,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ Опалиховская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Опалиховская средняя общеобразовательная школа",
    INN: 5024028830,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization:
      "МБОУ Петрово-Дальневская средняя общеобразовательная школа",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение Петрово-Дальневская средняя общеобразовательная школа",
    INN: 5024028727,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: 'МБОУ средняя общеобразовательная школа "Мозаика"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа "Мозаика"',
    INN: 5024189330,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №1",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №1",
    INN: 5024028759,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа №10 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №10 с углубленным изучением отдельных предметов",
    INN: 5024028854,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №11",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 11",
    INN: 5024028847,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №12",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №12",
    INN: 5024028702,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа №14 микрорайона «Павшинская пойма»",
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №14  микрорайона "Павшинская пойма"',
    INN: 5024084760,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №15",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №15",
    INN: 5024094046,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №16",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 16",
    INN: 5024130110,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №18",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №18",
    INN: 5024156020,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №19",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 19  ",
    INN: 5024163564,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №20",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 20",
    INN: 5024164790,
  },
  {
    Region: "Московская область",
    Municipality: "Красногорск городской округ",
    EduОrganization: "МБОУ Ульяновская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Ульяновская средняя общеобразовательная школа",
    INN: 5024028766,
  },
  {
    Region: "Московская область",
    Municipality: "Краснознаменск городской округ",
    EduОrganization:
      "МБОУ Гимназия №1 городского округа Краснознаменск Московской области",
    FullEduОrganization:
      "Муниципальное бюджетное образовательное учреждение Гимназия № 1",
    INN: 5006008380,
  },
  {
    Region: "Московская область",
    Municipality: "Краснознаменск городской округ",
    EduОrganization:
      "МБОУ Гимназия №2 городского округа Краснознаменск Московской области",
    FullEduОrganization:
      "Муниципальное бюджетное образовательное учреждение Гимназия №2 г.о.Краснознаменск Московской области",
    INN: 5006008397,
  },
  {
    Region: "Московская область",
    Municipality: "Краснознаменск городской округ",
    EduОrganization:
      "МБОУ Лицей №1 им. Титова Г.С. городского округа Краснознаменск Московской области",
    FullEduОrganization:
      "Муниципальное бюджетное образовательное учреждение Лицей №1 им. Г.С.Титова",
    INN: 5006008407,
  },
  {
    Region: "Московская область",
    Municipality: "Краснознаменск городской округ",
    EduОrganization:
      "МБОУ Средняя общеобразовательная школа №4 с углубленным изучением отдельных предметов им. Г.К. Жукова городского округа Краснознаменск Московской области",
    FullEduОrganization:
      "МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА № 4 С УГЛУБЛЕННЫМ ИЗУЧЕНИЕМ ОТДЕЛЬНЫХ ПРЕДМЕТОВ ИМ. Г.К. ЖУКОВА ГОРОДСКОГО ОКРУГА КРАСНОЗНАМЕНСК МОСКОВСКОЙ ОБЛАСТИ  ",
    INN: 5006008414,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: 'МАОУ "Бутовская средняя общеобразовательная школа №2"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение "Бутовская средняя общеобразовательная школа№2"',
    INN: 5003119451,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: 'МАОУ "Видновская гимназия"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение "Видновская гимназия" ',
    INN: 5003021537,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: 'МАОУ "Видновская средняя общеобразовательная школа №10"',
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение «Видновская средняя общеобразовательная школа № 10» ",
    INN: 5003119518,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: 'МАОУ "Видновская средняя общеобразовательная школа №11"',
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение «Видновская средняя общеобразовательная школа №11» ",
    INN: 5003123970,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: 'МАОУ "Видновский художественно-технический лицей"',
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение «Видновский художественно-технический лицей» ",
    INN: 5003021576,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: 'МАОУ "Образовательный центр "Старт"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение «Образовательный центр "Старт"',
    INN: 5003158002,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: 'МАОУ "Образовательный центр "Успех"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение "Образовательный центр "Успех"',
    INN: 5003157993,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization:
      'МАОУ "Средняя общеобразовательная школа совхоза им. Ленина"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение "Средняя общеобразовательная школа совхоза им. Ленина"',
    INN: 5003021375,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: "МАОУ Видновская средняя общеобразовательная школа №9",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Видновская средняя общеобразовательная школа № 9",
    INN: 5003082875,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: 'МБОУ "Бутовская средняя общеобразовательная школа №1"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Бутовская средняя общеобразовательная школа №1" ',
    INN: 5003107544,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: 'МБОУ "Видновская средняя общеобразовательная школа №2"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Видновская средняя общеобразовательная школа № 2" ',
    INN: 5003021625,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization:
      'МБОУ "Видновская средняя общеобразовательная школа №5 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Видновская средняя школа № 5 с углубленным изучением отдельных предметов"',
    INN: 5003021544,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: 'МБОУ "Лопатинская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Лопатинская средняя общеобразовательная школа"',
    INN: 5003130720,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: 'МБОУ "Мисайловская средняя общеобразовательная школа №1"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Мисайловская средняя общеобразовательная школа № 1"',
    INN: 5003142740,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: 'МБОУ "Молоковская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Молоковская средняя общеобразовательная школа"',
    INN: 5003021505,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: "МБОУ Видновская средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Видновская средняя общеобразовательная школа № 1",
    INN: 5003021569,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: "МБОУ Видновская средняя общеобразовательная школа №7",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Видновская средняя общеобразовательная школа №7",
    INN: 5003021551,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization: "МБОУ Володарская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Володарская средняя общеобразовательная школа",
    INN: 5003021590,
  },
  {
    Region: "Московская область",
    Municipality: "Ленинский городской округ",
    EduОrganization:
      "МБОУ Развилковская  средняя общеобразовательная школа с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Развилковская средняя общеобразовательная школа с углубленным изучением отдельных предметов",
    INN: 5003021664,
  },
  {
    Region: "Московская область",
    Municipality: "Лобня городской округ",
    EduОrganization: "МБОО средняя общеобразовательная школа №10",
    FullEduОrganization:
      "Муниципальная бюджетная общеобразовательная организация средняя общеобразовательная школа № 10 ",
    INN: 5047157119,
  },
  {
    Region: "Московская область",
    Municipality: "Лобня городской округ",
    EduОrganization: "МБОУ Лицей",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение лицей",
    INN: 5025009660,
  },
  {
    Region: "Московская область",
    Municipality: "Лобня городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №1",
    INN: 5025013554,
  },
  {
    Region: "Московская область",
    Municipality: "Лобня городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №2 ",
    INN: 5025013466,
  },
  {
    Region: "Московская область",
    Municipality: "Лобня городской округ",
    EduОrganization:
      "МБОУ Средняя общеобразовательная школа №3 им. Героя Советского Союза Борисова В.А.",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №3 имени Героя Советского Союза Василия Александровича Борисова",
    INN: 5025009710,
  },
  {
    Region: "Московская область",
    Municipality: "Лобня городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №4",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №4",
    INN: 5025014050,
  },
  {
    Region: "Московская область",
    Municipality: "Лобня городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №5",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №5 ",
    INN: 5025013956,
  },
  {
    Region: "Московская область",
    Municipality: "Лобня городской округ",
    EduОrganization:
      "МБОУ Средняя общеобразовательная школа №6 им. Сахнова Вячеслава Ивановича",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа №6 имени Сахнова Вячеслава Ивановича",
    INN: 5025009727,
  },
  {
    Region: "Московская область",
    Municipality: "Лобня городской округ",
    EduОrganization:
      "МБОУ Средняя общеобразовательная школа №7 им. Орлова Б.Н.",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №7  им. Орлова Б.Н.",
    INN: 5025009734,
  },
  {
    Region: "Московская область",
    Municipality: "Лобня городской округ",
    EduОrganization:
      "МБОУ Средняя общеобразовательная школа №8 им. Героя Советского Союза Будника Г.Д.",
    FullEduОrganization:
      "Муниципальное бюджетное образовательное учреждение средняя общеобразовательная школа №8 имени Героя Советского Союза Будника Гавриила Дмитриевича ",
    INN: 5025009614,
  },
  {
    Region: "Московская область",
    Municipality: "Лобня городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №9",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 9  ",
    INN: 5025024891,
  },
  {
    Region: "Московская область",
    Municipality: "Лобня городской округ",
    EduОrganization:
      "МБС(К)ОУ для обучающихся, воспитанников с ограниченными возможностями здоровья специальная (коррекционная) общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное специальное (коррекционное) образовательное учреждение для обучающихся, воспитанников с ограниченными возможностями здоровья специальная (коррекционная) общеобразовательная школа (МБСКОУ СКОШ)  ",
    INN: 5025014244,
  },
  {
    Region: "Московская область",
    Municipality: "Лосино-Петровский городской округ",
    EduОrganization: "МАОУ Свердловская средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Свердловская средняя общеобразовательная школа №2 городского округа Лосино-Петровский Московской области ",
    INN: 5050114718,
  },
  {
    Region: "Московская область",
    Municipality: "Лосино-Петровский городской округ",
    EduОrganization:
      "МБОУ Биокомбинатовская средняя общеобразовательная школа п. Биокомбината",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение Биокомбинатовская средняя общеобразовательная школа поселка Биокомбината городского округа Лосино-Петровский Московской области  ",
    INN: 5050032110,
  },
  {
    Region: "Московская область",
    Municipality: "Лосино-Петровский городской округ",
    EduОrganization:
      "МБОУ Свердловская средняя общеобразовательная школа им. Михаила Петровича Марченко",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Свердловска общеобразовательная средняя школа имени Михаила Петровича Марченко Московский области",
    INN: 5050032007,
  },
  {
    Region: "Московская область",
    Municipality: "Лосино-Петровский городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное  учреждение средняя общеобразовательная школа №1 городского округа Лосино-Петровский",
    INN: 5050027945,
  },
  {
    Region: "Московская область",
    Municipality: "Лосино-Петровский городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа №2 им. В.В. Дагаева",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №2 имени В.В. Дагаева городского округа Лосино-Петровский ",
    INN: 5050027952,
  },
  {
    Region: "Московская область",
    Municipality: "Лотошино городской округ",
    EduОrganization: 'МОУ "Лотошинская средняя общеобразовательная школа №1"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лотошинская средняя общеобразовательная школа №1"',
    INN: 5071003945,
  },
  {
    Region: "Московская область",
    Municipality: "Лотошино городской округ",
    EduОrganization: 'МОУ "Лотошинская средняя общеобразовательная школа №2"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учредение "Лотошинская средняя общеобразовательная школа № 2" ',
    INN: 5071003913,
  },
  {
    Region: "Московская область",
    Municipality: "Лотошино городской округ",
    EduОrganization: 'МОУ "Микулинская гимназия"',
    FullEduОrganization:
      'Муниципальное общеобразовательной учреждение "Микулинская гимназия"',
    INN: 5071003906,
  },
  {
    Region: "Московская область",
    Municipality: "Лотошино городской округ",
    EduОrganization: 'МОУ "Ошейкинская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Ошейкинская средняя общеобразовательная школа"',
    INN: 5071003977,
  },
  {
    Region: "Московская область",
    Municipality: "Лотошино городской округ",
    EduОrganization: 'МОУ "Ушаковская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учпеждение "Ушаковская средняя общеобразовательная школа"',
    INN: 5071004032,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization: 'МБОУ "Астаповская основная общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Астаповская основная общеобразовательная школа"',
    INN: 5072713000,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization:
      'МБОУ "Белоомутская основная общеобразовательная школа №2"',
    FullEduОrganization:
      'муниципальное бюджное общеобразовательное учреждение "Белоомутская основная общеобразовательная школа № 2"  ',
    INN: 5072712976,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization: 'МБОУ "Белоомутская средняя общеобразовательная школа №1"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Белоомутская средняя общеобразовательная школа №1"',
    INN: 5072713024,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization: 'МБОУ "Выкопанская средняя общеобразовательная школа"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Выкопанская средняя общеобразовательная школа"',
    INN: 5072713070,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization: 'МБОУ "Газопроводская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Газопроводская средняя общеобразовательная школа"',
    INN: 5072713088,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization: 'МБОУ "Гимназия №10"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Гимназия № 10"',
    INN: 5072713200,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization:
      'МБОУ "Дединовская школа-интернат среднего общего образования"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Дединовская школа-интернат среднего общего образования"',
    INN: 5072713105,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization:
      'МБОУ "Краснопоймовская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Краснопоймовская средняя общеобразовательная школа" ',
    INN: 5072712951,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization: 'МБОУ "Ловецкая средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Ловецкая средняя общеобразовательная школа"',
    INN: 5072712990,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization: 'МБОУ "Луховицкая средняя общеобразовательная школа №1"',
    FullEduОrganization:
      'Муниципальное бюджетное образовательное учреждение "Луховицкая средняя общеобразовательная школа №1"',
    INN: 5072713151,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization: 'МБОУ "Луховицкая средняя общеобразовательная школа №2"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Луховицкая средняя общеобразовательная школа № 2"',
    INN: 5072713169,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization: 'МБОУ "Луховицкая средняя общеобразовательная школа №9"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Луховицкая средняя общеобразовательная школа №9"',
    INN: 5072713190,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization: 'МБОУ "Луховицкий лицей"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Луховицкий лицей" ',
    INN: 5072713144,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization: 'МБОУ "Матырская основная общеобразовательная школа"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Матырская основная общеобразовательная школа"',
    INN: 5072713063,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization: 'МБОУ "Орешковская основная общеобразовательная школа"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Орешковская основная общеобразовательная школа"',
    INN: 5072713095,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization: 'МБОУ "Павловская основная общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Павловская основная общеобразовательная школа"',
    INN: 5072713031,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization:
      'МБОУ "Фруктовская средняя общеобразовательная школа имени Героя Российской Федерации Малочуева Олега Григорьевича"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Фруктовская средняя общеобразовательная школа имени Героя Российской Федерации Малочуева Олега Григорьевича" ',
    INN: 5072712937,
  },
  {
    Region: "Московская область",
    Municipality: "Луховицы городской округ",
    EduОrganization:
      'МКОУ "Белоомутская школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное казенное общеобразовательное учреждение "Белоомутская школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    INN: 5072000224,
  },
  {
    Region: "Московская область",
    Municipality: "Лыткарино городской округ",
    EduОrganization:
      'МБОУ "Школа №8 для обучающихся с ограниченными возможностями здоровья городского округа Лыткарино Московской области"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Школа №8 для обучающихся с ограниченными возможностями здоровья  городского округа Лыткарино Московской области»",
    INN: 5026008162,
  },
  {
    Region: "Московская область",
    Municipality: "Лыткарино городской округ",
    EduОrganization: "МОУ Гимназия №1",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Гимназия №1  ",
    INN: 5026008099,
  },
  {
    Region: "Московская область",
    Municipality: "Лыткарино городской округ",
    EduОrganization: "МОУ Гимназия №4",
    FullEduОrganization:
      "муниципальное общеобразовательное учреждение гимназия №4",
    INN: 5026008123,
  },
  {
    Region: "Московская область",
    Municipality: "Лыткарино городской округ",
    EduОrganization: "МОУ Гимназия №7",
    FullEduОrganization:
      "муниципальное общеобразовательное учреждение гимназия №7 ",
    INN: 5026008155,
  },
  {
    Region: "Московская область",
    Municipality: "Лыткарино городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №2",
    FullEduОrganization:
      "муниципальное общеобразовательное учреждение средняя общеобразовательная школа №2 ",
    INN: 5026008109,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: 'МОУ "Инженерно-технологический лицей"',
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение «Инженерно-технологический лицей» муниципального образования городского округа Люберцы Московской области ",
    INN: 5027277979,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: 'МОУ "Лингвистическая школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лингвистическая школа" муниципального образования городской округ Люберцы Московской области',
    INN: 5027296410,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: 'МОУ "Лицей №15" (среднее общее образование)',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей № 15" имени А.М. Черёмухина муниципального образования городской округ Люберцы Московской области',
    INN: 5027177205,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ Гимназия №1",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение гимназия № 1 муниципального образования городской округ Люберцы Московской области",
    INN: 5027093379,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: 'МОУ Гимназия №16 "Интерес"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение гимназия № 16 "Интерес" муниципального образования городской округ Люберцы Московской области ',
    INN: 5027200535,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ Гимназия №18",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение гимназия № 18 муниципального образования городской округ Люберцы Московской области",
    INN: 5027021590,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ Гимназия №20 им. Героя Советского Союза Н.Д. Дугина",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение гимназия № 20 имени Героя Советского Союза Н.Д. Дугина муниципального образования городской округ Люберцы Московской области",
    INN: 5027093361,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ Гимназия №24",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Гимназия №24",
    INN: 5027093450,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ Гимназия №41",
    FullEduОrganization:
      "муниципальное общеобразовательное учреждение гимназия №41 муниципального образования городской округ Люберцы Московской области ",
    INN: 5027037744,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization:
      "МОУ Гимназия №44 им. Героя Советского Союза Д.Л. Калараша",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение гимназия № 44 имени Героя Советского Союза Д.Л. Калараша муниципального образования городской округ Люберцы Московской области ",
    INN: 5027048432,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ Гимназия №46",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение гимназия №46 муниципвльного образования городской округ Люберцы Московской области",
    INN: 5027079737,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ Гимназия №5",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение гимназия №5 муниципального образования городской округ Люберцы Московской области",
    INN: 5027093114,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ Гимназия №56",
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Гимназия № 56" муниципального огбразования городской округ Люберцы Московской области',
    INN: 5027072467,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ Кадетская школа (среднее общее образование)",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Кадетская школа муниципального образования городской округ Люберцы",
    INN: 5027092865,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ Красковская средняя общеобразовательная школа  №55",
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ "КРАСКОВСКАЯ СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА № 55" МУНИЦИПАЛЬНОГО ОБРАЗОВАНИЯ ГОРОДСКОЙ ОКРУГ ЛЮБЕРЦЫ МОСКОВСКОЙ ОБЛАСТИ',
    INN: 5027072481,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ Лицей №12",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение лицей №12 муниципального образования городской округ Люберцы Московской области ",
    INN: 5027071576,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ Лицей №4",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение лицей № 4 муниципального образования городской округ Люберцы Московской области",
    INN: 5027093481,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ Октябрьская средняя общеобразовательная школа №54",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Октябрьская средняя общеобразовательная школа №54 муниципального образования городской округ Люберцы Московской области ",
    INN: 5027202807,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization:
      "МОУ сельская Жилинская средняя общеобразовательная школа №23",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение сельская Жилинская средняя общеобразовательная школа №23 муниципального образования городской округ Люберцы Московской области",
    INN: 5027084279,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №10",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 10 муниципального образования городской округ Люберцы Московской области ",
    INN: 5027009602,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №21",
    FullEduОrganization:
      "муниципальное общеобразовательное учреждение средняя общеобразовательная школа №21 муниципального образования городской округ Люберцы Московской области  ",
    INN: 5027092047,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №26",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 26 муниципального образования городской округ Люберцы Московской области",
    INN: 5027319731,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №27",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа №27 муниципального образования  городской округ Люберцы Московской области",
    INN: 5027213541,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №28",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждения средняя общеобразовательная школа № 28 муниципального образования городской округ Люберцы Московской области",
    INN: 5027254749,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №51",
    FullEduОrganization:
      "МУНИЦИПАЛЬНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА № 51 МУНИЦИПАЛЬНОГО ОБРАЗОВАНИЯ ГОРОДСКОЙ ОКРУГ ЛЮБЕРЦЫ МОСКОВСКОЙ ОБЛАСТИ",
    INN: 5027298288,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №52",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 52 муниципального образования городской округ Люберцы Московской области",
    INN: 5027080080,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №53",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 53 муниципального образования городской округ Люберцы Московской области ",
    INN: 5027080002,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №8",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 8 муниципального образования городской округ Люберцы Московской области",
    INN: 5027092858,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: "МОУ Токаревская основная общеобразовательная школа №22",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Токаревская сельская основная общеобразовательная школа №22 муниципального образования городской округ Люберцы Московской области",
    INN: 5027083652,
  },
  {
    Region: "Московская область",
    Municipality: "Люберцы городской округ",
    EduОrganization: 'МОУ школа-интернат №3 "Развитие"',
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение школа - интернат №3 для обучающихся с ограниченными возможностями здоровья муниципального образования городской округ Люберцы Московской области ",
    INN: 5027118538,
  },
  {
    Region: "Московская область",
    Municipality: "Можайский городской округ",
    EduОrganization: 'МБОУ средняя общеобразовательная школа "Гармония"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа "Гармония" г. Можайска  ',
    INN: 5028028936,
  },
  {
    Region: "Московская область",
    Municipality: "Можайский городской округ",
    EduОrganization: "МОУ Гимназия №4",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Гимназия №4 г. Можайска",
    INN: 5028017003,
  },
  {
    Region: "Московская область",
    Municipality: "Можайский городской округ",
    EduОrganization: 'МОУ средняя общеобразовательная школа "Лидер"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение средняя общеобразовательная школа "Лидер"',
    INN: 5028016970,
  },
  {
    Region: "Московская область",
    Municipality: "Можайский городской округ",
    EduОrganization: 'МОУ средняя общеобразовательная школа "Перспектива"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение средняя общеобразовательная школа "Перспектива"',
    INN: 5028017050,
  },
  {
    Region: "Московская область",
    Municipality: "Можайский городской округ",
    EduОrganization: 'МОУ средняя общеобразовательная школа "Созвездие Вента"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение средняя общеобразовательная школа "Созвездие Вента"',
    INN: 5028016899,
  },
  {
    Region: "Московская область",
    Municipality: "Можайский городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Средняя общеобразовательная школа №1г.Можайска",
    INN: 5028017010,
  },
  {
    Region: "Московская область",
    Municipality: "Можайский городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение - Средняя общеобразовательная школа № 2 г.Можайска",
    INN: 5028017170,
  },
  {
    Region: "Московская область",
    Municipality: "Можайский городской округ",
    EduОrganization: "МОУ - средняя общеобразовательная школа №3",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение-Средняя общеобразовательная школа №3 города Можайска  ",
    INN: 5028017074,
  },
  {
    Region: "Московская область",
    Municipality: "Молодежный городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа г.о. Молодежный",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа городского округа Молодёжный Московской области",
    INN: 5030035360,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Гимназия №1"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учрежденипе "Гимназия № 1"  ',
    INN: 5029100127,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Гимназия №16"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Гимназия № 16"',
    INN: 5029100046,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Гимназия №17"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Гимназия № 17"',
    INN: 5029100180,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Лицей №15"',
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение «Лицей № 15»",
    INN: 5029100134,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Лицей №34"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Лицей № 34"',
    INN: 5029234071,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Марфинская средняя общеобразовательная школа"',
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение «Марфинская средняя общеобразовательная школа»",
    INN: 5029100423,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Многопрофильная лингвистическая гимназия №33"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Многопрофильная лингвистическая гимназия №33"',
    INN: 5029223425,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Поведниковская средняя общеобразовательная школа"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Поведниковская средняя общеобразовательная школа" ',
    INN: 5029100470,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №10 им. А.К. Астрахова"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 10 им. А.К.Астрахова"  ',
    INN: 5029100230,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №12"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 12"',
    INN: 5029100286,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №14"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 14"',
    INN: 5029100222,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №19"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №19"',
    INN: 5029100416,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №24 им. 9-ой Гвардейской Краснознаменной стрелковой дивизии"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 24 имени 9-ой Гвардейской Краснознаменной стрелковой дивизии" ',
    INN: 5029100198,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №27"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 27"',
    INN: 5029100254,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №29"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №29"',
    INN: 5029072783,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №32"',
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение “Средняя общеобразовательная школа № 32”  ",
    INN: 5029212511,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №35 имени дважды Героя Советского Союза В.В. Рюмина"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 35"',
    INN: 5029279040,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №3 имени Б.А. Феофанова"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №3 имени  Б.А.Феофанова""',
    INN: 5029100328,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №4"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 4" ',
    INN: 5029100310,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №6"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение"Средняя общеообразовательная школа № 6" ',
    INN: 5029100173,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №8"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №8" ',
    INN: 5029100335,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №9"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 9"',
    INN: 5029100208,
  },
  {
    Region: "Московская область",
    Municipality: "Мытищи городской округ",
    EduОrganization:
      'МБОУ "Школа для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Школа для обучающихся с ограниченными возможностями здоровья" ',
    INN: 5029100455,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization:
      "МAОУ Апрелевская средняя общеобразовательная школа №3 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Апрелевская средняя общеобразовательная школа № 3 с углубленным изучением отдельных предметов",
    INN: 5030032506,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МAОУ лицей им. Героя России В.Волошиной",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение лицей имени Героя России Веры Волошиной",
    INN: 5030032249,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization:
      "МAОУ Наро-Фоминская средняя общеобразовательная школа №3 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Наро-Фоминская средняя общеобразовательная школа № 3 с углубленным изучением отдельных  предметов имени Героя Советского Союза Д.Д. Погодина",
    INN: 5030031887,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization:
      "МАОУ Алабинская средняя общеобразовательная школа с углубленным изучением отдельных предметов им. Героя Российской Федерации С.А. Ашихмина",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Алабинская средняя общеобразовательная школа с углубленным изучением отдельных предметов имени Героя Российской Федерации С.А. Ашихмина",
    INN: 5030032143,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МАОУ Апрелевская средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Апрелевская средняя общеобразовательная школа №1",
    INN: 5030032344,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МАОУ Наро-Фоминская средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Наро-Фоминская средняя общеобразовательная школа №1",
    INN: 5030031862,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МАОУ Селятинская средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Селятинская средняя общеобразовательная школа № 1",
    INN: 5030032457,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МАОУ Селятинская средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Селятинская средняя общеобразовательная школа №2",
    INN: 5030032464,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МБОУ Атепцевская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Атепцевская средняя общеобразовательная школа",
    INN: 5030032030,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МБОУ Васильчиновская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Васильчиновская средняя общеобразовательная школа ",
    INN: 5030032263,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МБОУ Верейская средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Верейская средняя общеобразовательная школа №1",
    INN: 5030034006,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МБОУ Веселевская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Веселёвская средняя общеобразовательная школа",
    INN: 5030032094,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization:
      "МБОУ Вышегородская школа-интернат для обучающихся с ограниченными возможностями здоровья",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Вышегородская школа-интернат для обучающихся с ограниченными возможностями здоровья ",
    INN: 5030034623,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МБОУ Назарьевская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Назарьевская средняя общеобразовательная школа ",
    INN: 5030032288,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization:
      "МБОУ Наро-Фоминская средняя общеобразовательная школа №4 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Наро-Фоминская средняя общеобразовательная школа № 4 с углубленным изучением отдельных предметов",
    INN: 5030031894,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization:
      "МБОУ Наро-Фоминская средняя общеобразовательная школа №5 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Наро-Фоминская средняя общеобразовательная школа №5 с углубленным изучением отдельных предметов",
    INN: 5030031904,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization:
      "МБОУ Наро-Фоминская средняя общеобразовательная школа №6 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Наро-Фоминская средняя общеобразовательная школа №6 с углубленным изучением отдельных предметов ",
    INN: 5030031911,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МБОУ Наро-Фоминская средняя общеобразовательная школа №7",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Наро-Фоминская средняя общеобразовательная школа №7",
    INN: 5030031929,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization:
      "МБОУ Наро-Фоминская средняя общеобразовательная школа №9 им. дважды Героя Советского Союза, лётчика-космонавта В.В. Лебедева",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Наро-Фоминская средняя общеобразовательная школа № 9 имени дважды Героя Советского Союза, лётчика-космонавта В.В. Лебедева",
    INN: 5030031943,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization:
      "МБОУ Наро-Фоминская школа для обучающихся с ограниченными возможностями здоровья",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Наро-Фоминская школа для обучающихся с ограниченными возможностями здоровья",
    INN: 5030033027,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МБОУ Ново-Ольховская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Ново- Ольховская средняя общеобразовательная школа",
    INN: 5030032217,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МБОУ Петровская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Петровская средняя общеобразовательная школа имени Героя Российской Федерации Д.В.Межуева ",
    INN: 5030032136,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МБОУ Софьинская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Софьинская средняя общеобразовательная школа",
    INN: 5030032150,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МБОУ Татищевская основная общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Татищевская основная общеобразовательная школа ",
    INN: 5030032231,
  },
  {
    Region: "Московская область",
    Municipality: "Наро-Фоминский городской округ",
    EduОrganization: "МБОУ Шустиковская основная общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Шустиковская основная общеобразовательная школа",
    INN: 5030032224,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization:
      'МАОУ "Православная гимназия во имя преподобного Саввы Сторожевского" (основное общее образование)',
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение «Православная гимназия во имя преподобного Саввы Сторожевского» города Звенигорода",
    INN: 5015250524,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МАОУ Зареченская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Зареченская средняя общеобразовательная школа",
    INN: 5032036440,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МАОУ Одинцовский лицей №6 им. А.С. Пушкина",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Одинцовский лицей № 6 им. А.С. Пушкина",
    INN: 5032036707,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: 'МБОУ "Первая школа имени М.А. Пронина" г. Звенигород',
    FullEduОrganization:
      'Муниципальное бюджетное учреждение "Первая школа имени М.А. Пронина" города Звенигород',
    INN: 5032333361,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Барвихинская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Барвихинская средняя общеобразовательная школа",
    INN: 5032036231,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Голицынская средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Голицынская средняя общеобразовательная школа №1",
    INN: 5032036087,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Голицынская средняя общеобразовательная школа  №2",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Голицынская средняя общеобразовательная школа №2",
    INN: 5032036094,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Жаворонковская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Жаворонковская средняя общеобразовательная школа",
    INN: 5032036111,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Захаровская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Захаровская средняя общеобразовательная школа",
    INN: 5032036263,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization:
      "МБОУ Кубинская средняя общеобразовательная школа №1 им. Героя РФ И.В. Ткаченко",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Кубинская средняя общеобразовательная школа №1 имени Героя Российской Федерации И.В.Ткаченко",
    INN: 5032036577,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization:
      "МБОУ Кубинская средняя общеобразовательная школа №2 им. Героя Советского Союза Безбородова В.П.",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Кубинская средняя общеобразовательная школа №2 имени Героя Советского Союза Безбородова В.П ",
    INN: 5032036288,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Лесногородская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Лесногородская средняя общеобразовательная школа ",
    INN: 5032036295,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Мало-Вяземская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Мало-Вяземская средняя общеобразовательная школа",
    INN: 5032036312,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Немчиновский лицей",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Немчиновский лицей  ",
    INN: 5032036697,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: 'МБОУ Образовательный центр "БАГРАТИОН"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ОБРАЗОВАТЕЛЬНЫЙ ЦЕНТР "БАГРАТИОН"',
    INN: 5032365437,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization:
      'МБОУ Образовательный центр "ФЛАГМАН" с углубленным изучением отдельных предметов',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Одинцовская средняя общеобразовательная школа №17 с углубленным изучением отдельных предметов",
    INN: 5032268730,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Одинцовская гимназия №11",
    FullEduОrganization:
      "МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ОДИНЦОВСКАЯ ГИМНАЗИЯ № 11",
    INN: 5032036739,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Одинцовская гимназия №13",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Одинцовская гимназия №13 ",
    INN: 5032057619,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Одинцовская гимназия №14",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Одинцовская гимназия №14 ",
    INN: 5032057489,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Одинцовская гимназия №4",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Одинцовская гимназия №4",
    INN: 5032036954,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Одинцовская гимназия №7",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Одинцовская гимназия № 7",
    INN: 5032036714,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Одинцовская лингвистическая гимназия",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Одинцовская лингвистическая гимназия",
    INN: 5032208970,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Одинцовская средняя общеобразовательная школа №1",
    FullEduОrganization:
      "МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ОДИНЦОВСКАЯ СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА № 1",
    INN: 5032036143,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Одинцовская средняя общеобразовательная школа №12",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Одинцовская средняя общеобразовательная школа №12",
    INN: 5032036506,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Одинцовская средняя общеобразовательная школа №3",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Одинцовская средняя общеобразовательная школа №3",
    INN: 5032036471,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Одинцовская средняя общеобразовательная школа №5",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Одинцовская средняя общеобразовательная школа №5 ",
    INN: 5032036489,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Одинцовская средняя общеобразовательная школа №8",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Одинцовская средняя общеобразовательная школа №8",
    INN: 5032036496,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization:
      "МБОУ Одинцовская средняя общеобразовательная школа №9 им. М.И. Неделина",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Одинцовская средняя общеобразовательная школа №9 имени М.И. Неделина",
    INN: 5032036150,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Одинцовский лицей №2",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Одинцовский лицей №2",
    INN: 5032057640,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Саввино-Каринская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Саввино-Каринская средняя общеобразовательная школа",
    INN: 5032036270,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: 'МБОУ Средняя общеобразовательная школа "Горки-Х"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа «Горки-Х»",
    INN: 5032036104,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Старогородковкая средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Старогородковская средняя общеобразовательная школа",
    INN: 5032036168,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Успенская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Успенская средняя общеобразовательная школа ",
    INN: 5032036175,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization: "МБОУ Часцовская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Часцовская средняя общеобразовательная школа",
    INN: 5032036552,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization:
      'МБОУ Школа "КвантУм" им. Героя Советского Союза Василия Фабричнова',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение школа "КвантУм" имени Героя Советского Союза Василия Фабричнова',
    INN: 5032239577,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization:
      'МКОУ для обучающихся с ограниченными возможностями здоровья Одинцовская общеобразовательная школа "Надежда"',
    FullEduОrganization:
      'Муниципальное казенное общеобразовательное учреждение для обучающихся с ограниченными возможностм Одинцовская общеобразовательная школа "Надежда"',
    INN: 5032055241,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization:
      'МКОУ для обучающихся с ограниченными возможностями здоровья Старогородковская общеобразовательная школа "Гармония"',
    FullEduОrganization:
      'Муниципальное казенное общеобразовательное учреждение для обучающихся с ограниченными возможностями здоровья Старогородковская общеобразовательная школа "Гармония"',
    INN: 5032083986,
  },
  {
    Region: "Московская область",
    Municipality: "Одинцовский городской округ",
    EduОrganization:
      'МКОУ, реализующее адаптированные основные общеобразовательные программы "Старогородковская  специальная (коррекционная) школа-интернат им. Заслуженного учителя РФ Фурагиной А.В."',
    FullEduОrganization:
      'Муниципальное казённое общеобразовательное учреждение, реализующее адаптированные основные общеобразовательные программы "Старогородковская специальная (коррекционная) школа - интернат имени Заслуженного учителя РФ Фурагиной А.В."',
    INN: 5032201615,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: 'МОУ "Демиховский лицей"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Демиховский лицей"',
    INN: 5073087108,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Авсюнинская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Авсюнинская средняя общеобразовательная школа",
    INN: 5073008931,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Верейская средняя общеобразовательная школа №19",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Верейская средняя общеобразовательная школа №19",
    INN: 5073008836,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Гимназия №14",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение гимназия №14  ",
    INN: 5034083935,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Гимназия №15",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение гимназия №15",
    INN: 5034083830,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Губинская средняя общеобразовательная школа",
    FullEduОrganization:
      "муниципальное общеобразовательное учреждение Губинская средняя общеобразовательная школа",
    INN: 5073008829,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Давыдовская гимназия",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Давыдовская гимназия",
    INN: 5073008787,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Давыдовский лицей",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Давыдовский лицей  ",
    INN: 5073008850,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Дрезненская средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Дрезненская средняя общеобразовательная школа № 1  ",
    INN: 5073008681,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Ильинская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Ильинская средняя общеобразовательная школа",
    INN: 5073008441,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Куровская гимназия",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Куровская гимназия",
    INN: 5073008226,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Куровская средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Куровская средняя общеобразовательная школа № 1",
    INN: 5073008321,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization:
      "МОУ Куровская средняя общеобразовательная школа №2 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Куровская средняя общеобразовательная школа № 2 с углублённым изучением отдельных предметов",
    INN: 5073087235,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Куровская средняя общеобразовательная школа №6",
    FullEduОrganization:
      "Муниципаьное общеобразовательное учреждение Куровская средняя общеобразовательная школа № 6",
    INN: 5073086961,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Ликино-Дулевская гимназия",
    FullEduОrganization:
      "муниципальное общеобразовательное учреждение Ликино-Дулёвская гимназия",
    INN: 5073009220,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization:
      "МОУ Ликино-Дулевская средняя общеобразовательная школа №5",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Ликино-Дулёвская средняя общеобразовательная школа № 5",
    INN: 5073008949,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Ликино-Дулевский лицей",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Ликино-Дулёвский лицей",
    INN: 5073008723,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Лицей",
    FullEduОrganization: "Муниципальное общеобразовательное учреждение лицей",
    INN: 5034083910,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization:
      "МОУ общеобразовательная школа №5 для обучающихся с ограниченными возможностями здоровья",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение общеобразовательная школа № 5 для обучающихся с ограниченными возможностями здоровья",
    INN: 5034084470,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Озерецкая средняя общеобразовательная школа №8",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Озерецкая средняя общеобразовательная школа № 8",
    INN: 5073087549,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 1",
    INN: 5034083702,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №10",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа №10",
    INN: 5034083950,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №11",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 11",
    INN: 5034083981,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization:
      "МОУ Средняя общеобразовательная школа №12 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 12 с углубленным изучением отдельных предметов",
    INN: 5034083999,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №16",
    FullEduОrganization:
      "муниципальное общеобразовательное учреждение средняя общеобразовательная школа №16",
    INN: 5034083967,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №17",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 17  ",
    INN: 5034084745,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization:
      "МОУ Средняя общеобразовательная школа №20 им. Н.З. Бирюкова",
    FullEduОrganization:
      "Муниципальное  общеобразовательное учреждение средняя общеобразовательная школа № 20 имени Н.З. Бирюкова",
    INN: 5034083861,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №22",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа №22",
    INN: 5034084093,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №26",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 26",
    INN: 5034083808,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №4",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 4",
    INN: 5034083815,
  },
  {
    Region: "Московская область",
    Municipality: "Орехово-Зуевский городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №6",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа №6 ",
    INN: 5034084061,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization:
      'МКОУ "Школа для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное казенное общеобразовательное  учреждение "Школа для обучающихся с ограниченными возможностями здоровья  Павлово-Посадского городского округа Московской области"',
    INN: 5035018689,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization:
      "МКОУ школа-интернат для обучающихся с ограниченными возможностями здоровья",
    FullEduОrganization:
      "Муниципальное казенное общеобразовательное учреждение школа-интернат для обучающихся с  ОВЗ Павлово-Посадского городского округа Московской области",
    INN: 5035020984,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization: 'МОУ "Гимназия"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Гимназия" городского округа Павловский Посад Московской области',
    INN: 5035020536,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization: 'МОУ "Лицей №1"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей №1"  городского округа Павловский Посад Московской области',
    INN: 5035021191,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization: 'МОУ "Лицей №2 им. В.В. Тихонова"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей №2 имени В. В. Тихонова" городского округа Павловский Посад Московской облатси',
    INN: 5035021530,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization: "МОУ Евсеевская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Евсеевская средняя общеобразовательная школа",
    INN: 5035020529,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization: "МОУ Лицей",
    FullEduОrganization: 'Муниципальное общеобразовательное учреждение "Лицей"',
    INN: 5035019820,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization:
      "МОУ Рахмановская средняя общеобразовательная школа им. Е.Ф. Кошенкова",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Рахмановская средняя общеобразовательная школа имени Е.Ф. Кошенкова городского округа Павловский Посад Московской области  ",
    INN: 5035020600,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization:
      "МОУ средняя общеобразовательная школа №10 им. дважды Героя Советского Союза летчика-космонавта СССР В.Ф. Быковского",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа №10 городского округа Павловский Посад Московской области",
    INN: 5034061434,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №11",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа №11 Павлово-Посадского городского округа Московской области",
    INN: 5035020374,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №13",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа №13 Павлово-Посадского городского округа Московской области ",
    INN: 5035021226,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №14",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа №14 Павлово-Посадского городского округа Московской области ",
    INN: 5035019883,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №16",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа  №16",
    INN: 5035019869,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization:
      "МОУ Средняя общеобразовательная школа №18 им. Н.В. Менчинского",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 18 имени Н.В. Менчинского  Павлово- Посадского городского округа  Московской области  ",
    INN: 5035003481,
  },
  {
    Region: "Московская область",
    Municipality: "Павловский Посад городской округ",
    EduОrganization: "МОУ Средняя общеобразовательная школа №9",
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №9"  Павлово-Посадского городского округа Московской области  ',
    INN: 5034060705,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МБОУ "Гимназия имени Подольских курсантов"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Гимназия имени Подольских курсантов"',
    INN: 5021011323,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МБОУ "Лицей Климовска"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Лицей  Климовска"',
    INN: 5021011362,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №30"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 30"  ',
    INN: 5036115460,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization:
      "МБОУ Средняя общеобразовательная школа №1 микрорайона Климовск",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №1",
    INN: 5021011348,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization:
      "МБОУ Средняя общеобразовательная школа №5 с углубленным изучением отдельных предметов микрорайона Климовск",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 5 с углубленным изучением отдельных предметов ",
    INN: 5021011316,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Гимназия №4"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Гимназия №4"',
    INN: 5036034330,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Гимназия №7"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Гимназия №7"',
    INN: 5036034394,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Лицей №1"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей №1"',
    INN: 5036034348,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Лицей №1 пос. Львовский"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей №1 пос. Львовский"',
    INN: 5074019407,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Лицей №23"',
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Лицей № 23",
    INN: 5036034242,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Лицей №26"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей №26" ',
    INN: 5036034757,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Лицей №5"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей №5"',
    INN: 5036034323,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №10"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа № 10"',
    INN: 5036034210,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №11"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №11"',
    INN: 5036034309,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №12"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №12" ',
    INN: 5036034299,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №13"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №13"',
    INN: 5036034203,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №14"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №14"',
    INN: 5036034281,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization:
      'МОУ "Средняя общеобразовательная школа №15 им. В.Д. Сабанеева"',
    FullEduОrganization:
      'муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №15 имени В.Д. Сабанеева"',
    INN: 5036034193,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №16"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №16" ',
    INN: 5036034387,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №17"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение " Средняя общеобразовательная школа№17"',
    INN: 5036034186,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization:
      'МОУ "Средняя общеобразовательная школа №18 им. Подольских курсантов"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №18 имени Подольских курсантов"',
    INN: 5036034274,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №19"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеоюразовтельная школа № 19"',
    INN: 5036034267,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №20"',
    FullEduОrganization:
      'муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №20 имени Героя Российской Федерации И.Д. Сергуна" ',
    INN: 5036034179,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №21"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа № 21" ',
    INN: 5036034250,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №22"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №22"',
    INN: 5036034161,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №24"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №24" ',
    INN: 5036034362,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №25"',
    FullEduОrganization:
      'Муниципальное общеобразоватльное учреждение "Средняя общеобразовательная школа № 25"',
    INN: 5036034235,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №27"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №27" ',
    INN: 5036034370,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization:
      'МОУ "Средняя общеобразовательная школа №29 им. П.И. Забродина"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа № 29 им. П.И.Забродина"',
    INN: 5036046030,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №31"',
    FullEduОrganization:
      'муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №31"',
    INN: 5036077704,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization:
      'МОУ "Средняя общеобразовательная школа №32 имени 177 истребительного авиационного московского полка"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа № 32 имени 177 истребительного авиационного московского полка" ',
    INN: 5036124023,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №33"',
    FullEduОrganization:
      'муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №33" ',
    INN: 5036142128,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №3"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №3"',
    INN: 5036034228,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №34"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "средняя общеобразовательная школа №34"',
    INN: 5036161843,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №35"',
    FullEduОrganization:
      'муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №35" Г.о. Подольск ',
    INN: 5036166834,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №36"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №36"',
    INN: 5074070428,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №6"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение " Средняя общеобразовательная школа №6"',
    INN: 5036034404,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №8"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа № 8"',
    INN: 5036034316,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization:
      'МОУ "Школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ "ШКОЛА-ИНТЕРНАТ ДЛЯ ОБУЧАЮЩИХСЯ С ОГРАНИЧЕННЫМИ ВОЗМОЖНОСТЯМИ ЗДОРОВЬЯ ГОРОДСКОГО ОКРУГА ПОДОЛЬСК МОСКОВСКОЙ ОБЛАСТИ"',
    INN: 5036037099,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: "МОУ Быковская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Быковская средняя общеобразовательная школа",
    INN: 5074019365,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization:
      "МОУ Дубровицкая средняя общеобразовательная школа им. Героя России А.Г. Монетова  ",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Дубровицкая средняя общеобразовательная школа имени Героя России А.Г. Монетова  ",
    INN: 5074019358,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: "МОУ Львовская средняя общеобразовательная школа №4",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Львовская средняя общеобразовательная школа № 4",
    INN: 5074019380,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа п. МИС",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа пос. МИС ",
    INN: 5074019260,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: "МОУ Сынковская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Сынковская средняя общеобразовательная школа",
    INN: 5074019439,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization:
      "МОУ Толбинская средняя общеобразовательная школа имени Героя Российской Федерации летчика-испытателя Н.Д. Куимова",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Толбинская средняя общеобразовательная школа имени Героя Российской Федерации летчика - испытателя Н.Д. Куимова",
    INN: 5074019245,
  },
  {
    Region: "Московская область",
    Municipality: "Подольск городской округ",
    EduОrganization: "МОУ Федюковская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Федюковская средняя общеобразовательная школа  имени Д.И. Зернова",
    INN: 5074019291,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МАОУ "Гимназия "Тарасовка"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение Городского округа Пушкинский "Гимназия "Тарасовка"',
    INN: 5038011064,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МАОУ "Гимназия №10" г. Пушкино',
    FullEduОrganization:
      "Муниципальное  автономное  общеобразовательное учреждение г. Пушкино Городского округа Пушкинский Московской области «Гимназия №10»",
    INN: 5038025204,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Ашукинский образовательный комплекс"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Пушкинский Московской области "Ашукинский образовательный комплекс""',
    INN: 5038025885,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Гимназия №3" г. Ивантеевка',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение г. Ивантеевка Городского округа Пушкинский "Гимназия № 3" ',
    INN: 5016010684,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Гимназия №6" г. Ивантеевка',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение г. Ивантеевка Городского округа Пушкинский Московской области «Гимназия №6»",
    INN: 5016010846,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Образовательный комплекс №11" г. Пушкино',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение г. Пушкино Городского округа Пушкинский Московской области «Образовательный комплекс №11» ",
    INN: 5038025564,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Образовательный комплекс №1" г. Пушкино',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение г. Пушкино Городского округа Пушкинский Московской области "Образовательный комплекс№1"',
    INN: 5038025187,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Образовательный комплекс №2"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Городского округа Пушкинский Московской области «Образовательный комплекс №2»",
    INN: 5038025420,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Образовательный комплекс №3" г. Пушкино',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение г.Пушкино Городского округа Пушкинский Московской области "Образовательный комплекс №3"',
    INN: 5038025620,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Образовательный комплекс №4" г. Пушкино',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Городского округа Пушкинский Московской области «Образовательный комплекс №4»",
    INN: 5038025677,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Образовательный комплекс №5" г. Пушкино',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение  г. Пушкино Городского округа  Пушкинский Московской области "Образовательный  комплекс №5"',
    INN: 5038025229,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Образовательный комплекс №7" г. Пушкино',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение г. Пушкино Городского округа Пушкинский "Образовательный комплекс  № 7"',
    INN: 5038025518,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Образовательный комплекс №8" г. Пушкино',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение г. Пушкино  Городского округа  Пушкинский Московской области "Образовательный комплекс №8"',
    INN: 5038025758,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Образовательный комплекс №9" г. Пушкино',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение г. Пушкино Городского округа Пушкинский Московской области "Образовательный комплекс №9" ',
    INN: 5038025405,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization:
      'МБОУ "Образовательный комплекс им. Героя России В.В. Матвеева"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Пушкинский Московской области "Образовательный комплекс имени Героя России В.В. Матвеева"',
    INN: 5038031920,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Образовательный центр №1"',
    FullEduОrganization:
      ' Муниципальное бюджетное общеобразовательное учреждение г. Ивантеевка Городского округа Пушкинский Московской области "Образовательный центр №1"',
    INN: 5016010660,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Образовательный центр №2" г. Ивантеевка',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение г. Ивантеевка Городского округа Пушкинский Московской области "Образовательный центр №2"',
    INN: 5016010652,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Образовательный центр №5" г. Ивантеевка',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение г. Ивантеевка Городского округа Пушкинский Московской области «Образовательный центр №5»",
    INN: 5016008131,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Образовательный центр №7" г. Ивантеевка',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение г. Ивантеевка Городского округа Пушкинский Московской области  «Образовательный центр №7»",
    INN: 5016010677,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Образовательный центр №8" г. Ивантеевка',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение г. Ивантеевка Городского округа Пушкинский  Московской области "Образовательный центр №8 "',
    INN: 5016010726,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Софринский образовательный комплекс"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Городского округа Пушкинский Московской области  "Софринский образовательный комплекс"',
    INN: 5038025959,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Центр образования №1" г. Красноармейск',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение г. Красноармейск Городского округа Пушкинский Московской области "Центр образования №1»',
    INN: 5023006262,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization: 'МБОУ "Центр образования №2" г. Красноармейск',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение г.Красноармейск Городского округа Пушкинский Московской области "Центр образования №2"',
    INN: 5023006255,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization:
      'МБОУ "Школа-интернат для обучающихся с ограниченными возможностями здоровья г. Красноармейск"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение г. Красноармейск Городского округа Пушкинский Московской области "Школа-интернат для обучающихся с ограниченными возможностями здоровья" ',
    INN: 5023006270,
  },
  {
    Region: "Московская область",
    Municipality: "Пушкинский городской округ",
    EduОrganization:
      'МБОУ "Школа-интернат для обучающихся с ограниченными возможностями здоровья г. Пушкино"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение г. Пушкино Городского округа Пушкинский Московской области «Школа-интернат с ограниченными возможностями здоровья»",
    INN: 5038029640,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: 'МОУ "Гимназия г. Раменское"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Гимназия г. Раменское"',
    INN: 5040039892,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: 'МОУ "Ильинская средняя общеобразовательная школа №26"',
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение «Ильинская средняя общеобразовательная школа No 26»",
    INN: 5040040263,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: 'МОУ "Островецкая средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Островецкая средняя общеобразовательная школа"',
    INN: 5040040753,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization:
      'МОУ "Удельнинская общеобразовательная школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Удельнинская общеобразовательная школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    INN: 5040046096,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization:
      'МОУ "Юровская общеобразовательная школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Юровская общеобразовательная школа-интернат для обучающихся с ограниченными возможностями здоровья" ',
    INN: 5040045776,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Быковская средняя общеобразовательная школа №15",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Быковская средняя общеобразовательная школа № 15  ",
    INN: 5040040270,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Власовская средняя общеобразовательная школа №13",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Власовская средняя общеобразовательная школа № 13 ",
    INN: 5040040979,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization:
      "МОУ Гжельская средняя общеобразовательная школа с изучением предметов художественно-эстетического цикла",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Гжельская средняя общеобразовательная школа с изучением художественно-эстетического цикла",
    INN: 5040040633,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ - Гимназия №2",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение - Гимназия №2 г.Раменское ",
    INN: 5040039652,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Дергаевская средняя общеобразовательная школа №23",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Дергаевская средняя общеобразовательная школа №23 ",
    INN: 5040099612,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Заворовская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Заворовская средняя общеобразовательная школа",
    INN: 5040039282,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Ильинская средняя общеобразовательная школа №25",
    FullEduОrganization:
      "муниципальное общеобразовательное учреждение Ильинская средняя общеобразовательная школа №25",
    INN: 5040040721,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Клишевская средняя общеобразовательная школа №12",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Клишевская средняя общеобразовательная школа № 12  ",
    INN: 5040039677,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Константиновская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Константиновская средняя общеобразовательная школа",
    INN: 5040040175,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Кратовская средняя общеобразовательная школа №98",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Кратовская средняя общеобразовательная школа № 98 ",
    INN: 5040014915,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Никитская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Никитская средняя общеобразовательная школа",
    INN: 5040039388,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization:
      "МОУ Ново-Харитоновская средняя общеобразовательная школа №10 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Ново-Харитоновская средняя общеобразовательная школа №10 с углубленным изучением отдельных предметов",
    INN: 5040040954,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Раменская средняя общеобразовательная школа №19",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Раменская средняя общеобразовательная школа №19 ",
    INN: 5040040841,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization:
      "МОУ Раменская средняя общеобразовательная школа №1 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "муниципальное общеобразовательное учреждение Раменская средняя общеобразовательная школа № 1 с углубленным изучением отдельных предметов",
    INN: 5040050254,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization:
      "МОУ Раменская средняя общеобразовательная школа №21 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Раменская средняя общеобразовательная школа № 21 с углубленным изучением отдельных предметов",
    INN: 5040039638,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization:
      'МОУ Раменская средняя общеобразовательная школа №35 "Вектор успеха"',
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Раменская средняя общеобразовательная школа №35 «Вектор успеха»",
    INN: 5040174556,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Раменская средняя общеобразовательная школа №4",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Раменская средняя общеобразовательная школа №4",
    INN: 5040039684,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Раменская средняя общеобразовательная школа №5",
    FullEduОrganization:
      "Муниципальное общеобращовательное учреждение Раменская средняя общеобразовательная школа №5  ",
    INN: 5040039821,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Раменская средняя общеобразовательная школа №6",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Раменская средняя общеобразовательная школа №6",
    INN: 5040040827,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Раменская средняя общеобразовательная школа №8",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Раменская средняя общеобразовательная школа №8",
    INN: 5040040351,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Раменская средняя общеобразовательная школа №9",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Раменская средняя общеобразовательная школа № 9",
    INN: 5040132771,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Речицкая средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Речицкая средняя общеобразовательная школа",
    INN: 5040040182,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Родниковская средняя общеобразовательная школа №32",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Родниковская средняя общеобразовательная школа",
    INN: 5040040802,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Рыболовская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Рыболовская средняя общеобразовательная школа",
    INN: 5040050550,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Софьинская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Софьинская средняя общеобразовательная школа ",
    INN: 5040039412,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №11 п. Дружба",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа №11 пос. Дружба ",
    INN: 5040040707,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization:
      "МОУ средняя общеобразовательная школа №22 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа №22  с углубленным изучением отдельных предметов",
    INN: 5040039405,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Удельнинская Гимназия",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Удельнинская гимназия",
    INN: 5040039557,
  },
  {
    Region: "Московская область",
    Municipality: "Раменский городской округ",
    EduОrganization: "МОУ Чулковская средняя общеобразовательная школа №20",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Чулковская Средняя общеобразовательная школа №20",
    INN: 5040040680,
  },
  {
    Region: "Московская область",
    Municipality: "Реутов городской округ",
    EduОrganization: 'МАОУ "Cредняя общеобразовательная школа №10"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение "Средняя общеобразовательная школа №10" ',
    INN: 5012079815,
  },
  {
    Region: "Московская область",
    Municipality: "Реутов городской округ",
    EduОrganization: 'МАОУ "Гимназия"',
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение «Гимназия» городского округа Реутов Московской области",
    INN: 5041012808,
  },
  {
    Region: "Московская область",
    Municipality: "Реутов городской округ",
    EduОrganization: 'МАОУ "Лицей"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение "Лицей"',
    INN: 5041014474,
  },
  {
    Region: "Московская область",
    Municipality: "Реутов городской округ",
    EduОrganization:
      'МБОУ "Начальная школа-детский сад "Лучик" для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Начальная школа-детский сад "Лучик" для обучающихся с ограниченными возможностями здоровья" города Реутов Московской области',
    INN: 5041015397,
  },
  {
    Region: "Московская область",
    Municipality: "Реутов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №1"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №1"  ',
    INN: 5041012879,
  },
  {
    Region: "Московская область",
    Municipality: "Реутов городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №2 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 2 с углублённым изучением отдельных предметов"',
    INN: 5041012861,
  },
  {
    Region: "Московская область",
    Municipality: "Реутов городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №3 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №3 с углубленным изучением отдельных предметов"',
    INN: 5041012854,
  },
  {
    Region: "Московская область",
    Municipality: "Реутов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №4"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №4"',
    INN: 5041012847,
  },
  {
    Region: "Московская область",
    Municipality: "Реутов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №5"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №5" ',
    INN: 5041012830,
  },
  {
    Region: "Московская область",
    Municipality: "Реутов городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №6 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №6 с углубленным изучением отдельных предметов" ',
    INN: 5041012822,
  },
  {
    Region: "Московская область",
    Municipality: "Реутов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №7"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №7" ',
    INN: 5041012815,
  },
  {
    Region: "Московская область",
    Municipality: "Рузский городской округ",
    EduОrganization: 'МАОУ "Гимназия №1"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение " Гимназия №1г.Рузы"',
    INN: 5075009754,
  },
  {
    Region: "Московская область",
    Municipality: "Рузский городской округ",
    EduОrganization: 'МАОУ "средняя общеобразовательная школа №3"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение "Средняя общеобразовательная школа №3 г. Рузы" ',
    INN: 5075010630,
  },
  {
    Region: "Московская область",
    Municipality: "Рузский городской округ",
    EduОrganization:
      'МАОУ кадетская школа-интернат "Первый Рузский казачий кадетский корпус имени Героя Советского Союза Л.М. Доватора"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение кадетская школа-интернат "Первый Рузский казачий кадетский корпус имени Героя Советского Союза Л.М. Доватора"',
    INN: 5075023847,
  },
  {
    Region: "Московская область",
    Municipality: "Рузский городской округ",
    EduОrganization: 'МБОУ "Дороховская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Дороховская средняя общеобразовательная школа"',
    INN: 5075009850,
  },
  {
    Region: "Московская область",
    Municipality: "Рузский городской округ",
    EduОrganization: 'МБОУ "Колюбакинская средняя общеобразовательная школа"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Колюбакинская средняя общеобразовательная школа"',
    INN: 5075009585,
  },
  {
    Region: "Московская область",
    Municipality: "Рузский городской округ",
    EduОrganization: 'МБОУ "Космодемьянская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Космодемьянская основная общеобразовательная школа"',
    INN: 5075009592,
  },
  {
    Region: "Московская область",
    Municipality: "Рузский городской округ",
    EduОrganization: 'МБОУ "Нестеровский лицей"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Нестеровский лицей"',
    INN: 5075009680,
  },
  {
    Region: "Московская область",
    Municipality: "Рузский городской округ",
    EduОrganization: 'МБОУ "Никольская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Никольская средняя общеобразовательная школа"  ',
    INN: 5075009610,
  },
  {
    Region: "Московская область",
    Municipality: "Рузский городской округ",
    EduОrganization: 'МБОУ "Покровская средняя общеобразовательная школа"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ "ПОКРОВСКАЯ СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА"',
    INN: 5075009715,
  },
  {
    Region: "Московская область",
    Municipality: "Рузский городской округ",
    EduОrganization: 'МБОУ "средняя общеобразовательная школа №2"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №2 г. Рузы"',
    INN: 5075009698,
  },
  {
    Region: "Московская область",
    Municipality: "Рузский городской округ",
    EduОrganization:
      'МБОУ "Тучковская специальная (коррекционная) школа-интернат VIII вида"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Тучковская специальная (коррекционная) школа-интернат VIII вида" ',
    INN: 5075011457,
  },
  {
    Region: "Московская область",
    Municipality: "Рузский городской округ",
    EduОrganization: 'МБОУ "Тучковская средняя общеобразовательная школа №1"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Тучковская средняя общеобразовательная школа №1"',
    INN: 5075009578,
  },
  {
    Region: "Московская область",
    Municipality: "Рузский городской округ",
    EduОrganization: 'МБОУ "Тучковская средняя общеобразовательная школа №2"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Тучковская  средняя общеобразовательная школа №2"',
    INN: 5075009659,
  },
  {
    Region: "Московская область",
    Municipality: "Рузский городской округ",
    EduОrganization:
      'МБОУ "Тучковская средняя общеобразовательная школа №3 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Туковская средняя школа №3 с углубленным изучением отдельных предметов"',
    INN: 5075009747,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Бужаниновская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Бужаниновская средняя общеобразовательная школа"',
    INN: 5042068754,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Васильевская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Васильевская средняя общеобразовательная школа"',
    INN: 5042068031,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Вечерняя (сменная) общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Вечерняя (сменная) общеобразовательная школа"',
    INN: 5042055650,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization:
      'МБОУ "Гимназия №5 им. Героя Советского Союза А.И. Алексеева"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Гимназия № 5 имени Героя Советского Союза А.И. Алексеева»",
    INN: 5042075350,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization:
      'МБОУ "Краснозаводская средняя общеобразовательная школа №1"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Краснозаводская средняя общеобразовательная школа №1"',
    INN: 5042068257,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization:
      'МБОУ "Краснозаводская средняя общеобразовательная школа №7 им. Н.С. Булычева"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Краснозаводская средняя общеобразовательная школа №7 имени Н.С. Булычева"" ',
    INN: 5042067990,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization:
      'МБОУ "Лицей №24 им. Героя Советского Союза А.В. Корявина"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Лицей №24 имени Героя Советского Союза А.В. Корявина»",
    INN: 5042067976,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization:
      'МБОУ "Основная общеобразовательная школа №7, осуществляющая деятельность по адаптированным основным образовательным программам" (специальная(коррекционная))',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Основная общеобразовательная школа №7, осуществляющая деятельность по адаптированным основным  образовательным программам» ",
    INN: 5042068401,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Сергиево-Посадская гимназия им. И.Б. Ольбинского"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Сергиево-Посадская гимназия имени И.Б. Ольбинского"',
    INN: 5042071154,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №11"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №11"',
    INN: 5042069532,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №1"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №1"',
    INN: 5042068218,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №14"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 14"  ',
    INN: 5042068190,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №15"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №15"',
    INN: 5042068514,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №16"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 16" ',
    INN: 5042010610,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №18 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №18 с углубленным изучением отдельных предметов"',
    INN: 5042010627,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №19 им. Героя Советского Союза М.К. Нехаева"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение  "Средняя общеобразовательная школа №19 имени Героя Советского Союза М.К.Нехаева"',
    INN: 5042068264,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №21"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 21"',
    INN: 5042067983,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №2"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ "СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА №2"',
    INN: 5042153382,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №25"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Средняя общеобразовательная школа № 25»",
    INN: 5042067905,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №26"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 26"',
    INN: 5042068472,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №28"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 28"  ',
    INN: 5042068810,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №4"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №4"',
    INN: 5042055628,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №5 г. Пересвета"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №5  г. Пересвета"',
    INN: 5042069211,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №6"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 6"',
    INN: 5042068271,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №8"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №8"',
    INN: 5042068521,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №8 г. Пересвета"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 8 г. Пересвета" ',
    INN: 5042068673,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Хотьковская средняя общеобразовательная школа №5"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Хотьковская средняя общеобразовательная школа №5',
    INN: 5042068793,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: 'МБОУ "Шеметовская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Шеметовская средняя общеобразовательная школа"',
    INN: 5042068384,
  },
  {
    Region: "Московская область",
    Municipality: "Сергиево-Посадский городской округ",
    EduОrganization: "МБОУ Хотьковская средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Хотьковская средняя общеобразовательная школа № 1",
    INN: 5042068730,
  },
  {
    Region: "Московская область",
    Municipality: "Серебряные Пруды городской округ",
    EduОrganization: 'МБОУ "Клемовская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Клёмовская средняя общеобразовательная школа"',
    INN: 5076006210,
  },
  {
    Region: "Московская область",
    Municipality: "Серебряные Пруды городской округ",
    EduОrganization: 'МБОУ "Крутовская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Крутовская средняя общеобразовательная школа"',
    INN: 5076006227,
  },
  {
    Region: "Московская область",
    Municipality: "Серебряные Пруды городской округ",
    EduОrganization: 'МБОУ "Петровская средняя общеобразовательная школа"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Петровская средняя общеобразовательная школа» ",
    INN: 5076006266,
  },
  {
    Region: "Московская область",
    Municipality: "Серебряные Пруды городской округ",
    EduОrganization:
      'МБОУ "Серебряно-Прудская средняя общеобразовательная школа им. Маршала В.И. Чуйкова"',
    FullEduОrganization:
      'Муниципальное бюджетное  общеобразовательное учреждение "Серебряно-Прудская средняя общеобразовательная школа имени маршала В.И. Чуйкова"',
    INN: 5076000874,
  },
  {
    Region: "Московская область",
    Municipality: "Серебряные Пруды городской округ",
    EduОrganization: 'МБОУ "Совхозная средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Совхозная средняя общеобразовательная школа"',
    INN: 5076005463,
  },
  {
    Region: "Московская область",
    Municipality: "Серебряные Пруды городской округ",
    EduОrganization: 'МБОУ "Узуновская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное  общеобразовательное учреждение "Узуновская средняя общеобразовательная школа"',
    INN: 5076006259,
  },
  {
    Region: "Московская область",
    Municipality: "Серебряные Пруды городской округ",
    EduОrganization: 'МБОУ "Шеметовская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Шеметовская средняя общеобразовательная школа"',
    INN: 5076006234,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization:
      "МАОУ Средняя общеобразовательная школа №1 им. Щукина Н.Д. г.о. Пущино",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение средняя общеобразовательная школа № 1 им. Щукина Н. Д. городского округа Пущино Московской области",
    INN: 5039006483,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Гимназия" г.о. Протвино',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Гимназия"',
    INN: 5037002275,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Гимназия №1"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Гимназия "1" ',
    INN: 5043009818,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization:
      'МБОУ "Липицкая средняя общеобразовательная школа им. Героя Советского Союза Е.П. Тарасова"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Липицкая средняя общеобразовательная школа имени Героя Советского Союза Евгения Петровича Тарасова"',
    INN: 5077010875,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Лицей" г.о. Протвино',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Лицей" ',
    INN: 5037001296,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Лицей №2" г.о. Протвино',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Лицей №2"',
    INN: 5037001144,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Оболенская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Оболенская средняя общеобразовательная школа" ',
    INN: 5077010900,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Пролетарская средняя общеобразовательная школа"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Пролетарская средняя общеобразовательная школа"',
    INN: 5077010804,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №10"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №10"',
    INN: 5043014053,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №11"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 11"',
    INN: 5043014060,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №12 с углубленным изучением отдельных предметов "Центр образования"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Средняя общеобразовательная школа № 12 с углубленным изучением отдельных предметов «Центр образования»  ",
    INN: 5043014078,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №13"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 13"',
    INN: 5043014913,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №1"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 1"',
    INN: 5043013941,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №16"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 16"',
    INN: 5043014092,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №17"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение " Средняя общеобразовательная школа № 17"',
    INN: 5043014102,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №18"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Средняя общеобразовательная школа № 18»",
    INN: 5043064417,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №19 им. Романа Катасонова"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ "СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА № 19 имени Романа Катасонова""',
    INN: 5043072288,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №2"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 2"',
    INN: 5043013966,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №3"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №3 ',
    INN: 5043013973,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №3" им. Д.Ф. Лавриненко г.о. Протвино',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №3" имени Д.Ф. Лавриненко',
    INN: 5037001183,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №4"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №4"',
    INN: 5043013980,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №5"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 5"',
    INN: 5043014007,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №6"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №6"',
    INN: 5043014014,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №7 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 7 с углубленным изучением отдельных предметов"  ',
    INN: 5043014021,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №9 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Средняя общеобразовательная школа № 9 с углубленным изучением  отдельных предметов»",
    INN: 5043014046,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ "Туровская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Туровская средняя общеобразовательная школа" ',
    INN: 5077010770,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization:
      'МБОУ "Школа №8 для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Школа № 8 для обучающихся с ограниченными возможностями здоровья"',
    INN: 5043013998,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МБОУ гимназия "Пущино" г.о. Пущино',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение гимназия "Пущино" г.о. Пущино Московской области',
    INN: 5039001559,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №3 г.о. Пущино",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №3 городского округа Пущино Московской области ",
    INN: 5039006589,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МОУ "Дашковская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Дашковская средняя общеобразовательная школа"',
    INN: 5077010917,
  },
  {
    Region: "Московская область",
    Municipality: "Серпухов городской округ",
    EduОrganization: 'МОУ "Куриловская гимназия"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Куриловская  гимназия"',
    INN: 5077012294,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization: 'МАОУ "Радумльский лицей-интернат"',
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение «Радумльский лицей-интернат» ",
    INN: 5044019230,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization:
      'МБОУ "Берсеневская общеобразовательная школа-интернат для детей с ограниченными возможностями"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Берсеневская общеобразовательная школа-интернат для детей с ограниченными возможностями здоровья" ',
    INN: 5044019512,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization: "МБОУ Андреевская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное образовательное учреждение Андреевская средняя общеобразовательная школа",
    INN: 5044019400,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization:
      "МБОУ Гимназия №6 им. дважды Героя Советского Союза Д.А. Драгунского",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение гимназия № 6 имени дважды Героя Советского Союза Д.А. Драгунского",
    INN: 5044019382,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization: "МБОУ Лицей №1 им. А. Блока",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Лицей № 1 им. А. Блока ",
    INN: 5044019336,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization: "МБОУ Лицей №7",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение лицей №7",
    INN: 5044019311,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization: "МБОУ Лицей №8",
    FullEduОrganization:
      "МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ЛИЦЕЙ № 8 ",
    INN: 5044019350,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization: "МБОУ Ложковская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Ложковская средняя общеобразовательная школа",
    INN: 5044019463,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization: "МБОУ Менделеевская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Менделеевская средняя общеобразовательная школа ",
    INN: 5044019431,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization: "МБОУ Обуховская средняя общеобразовательная школа",
    FullEduОrganization:
      "МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ОБУХОВСКАЯ СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА",
    INN: 5044019495,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization: "МБОУ Поваровская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Поваровская средняя общеобразовательная школа",
    INN: 5044019368,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization: 'МБОУ Средняя общеобразовательная школа "Лесные озера"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа "Лесные озера"',
    INN: 5044019449,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 2 г. Солнечногорска",
    INN: 5044019329,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №4",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 4",
    INN: 5044019343,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization:
      "МБОУ Средняя общеобразовательная школа №5 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 5 с углублённым изучением отдельных предметов",
    INN: 5044019375,
  },
  {
    Region: "Московская область",
    Municipality: "Солнечногорск городской округ",
    EduОrganization:
      "МБОУ Тимоновская средняя общеобразовательная школа с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Тимоновская средняя общеобразовательная школа с углублённым изучением отдельных предметов  ",
    INN: 5044019456,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization:
      'МБОУ "Большеалексеевская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Большеалексеевская средняя общеобразовательная школа" городского округа Ступино Московской области',
    INN: 5045025597,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization: 'МБОУ "Верзиловская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Верзиловская средняя общеобразовательная школа" городского округа Ступино Московской области',
    INN: 5045064620,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization: 'МБОУ "Жилевская средняя общеобразовательная школа"',
    FullEduОrganization:
      "Муниципальное бюджетное  общеобразовательное учреждение «Жилёвская средняя общеобразовательная школа» городского округа Ступино Московской области ",
    INN: 5045025580,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization: 'МБОУ "Ивановская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Ивановская средняя общеобразовательная школа" городского округа Ступино Московской области',
    INN: 5045025452,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization: 'МБОУ "Лицей №1"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Лицей №1" городского округа Ступино Московской области',
    INN: 5045032611,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization: 'МБОУ "Лицей №2"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Лицей № 2" городского округа Ступино Московской области',
    INN: 5045024667,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization:
      'МБОУ "Малинская средняя общеобразовательная школа им. П.М. Овсянкина"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Малинская средняя общеобразовательная школа имени П.М. Овсянкина" городского округа Ступино Московской области',
    INN: 5045025780,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization:
      'МБОУ "Мещеринская средняя общеобразовательная школа №1 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Мещеринская средняя общеобразовательная школа №1 с углублённым изучением отдельных предметов" городского округа Ступино Московской области',
    INN: 5045026030,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization:
      'МБОУ "Михневская средняя общеобразовательная школа с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Михневская средняя общеобразовательная школа с углублённым изучением отдельных предметов" городского округа Ступино Московской области',
    INN: 5045025244,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization: 'МБОУ "Семеновская средняя общеобразовательная школа"',
    FullEduОrganization:
      " Муниципальное бюджетное общеобразовательное учреждение «Семёновская средняя общеобразовательная школа» городского округа Ступино Московской области",
    INN: 5045025702,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №1 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 1 с углублённым изучением отдельных предметов" городского округа Ступино Московской области ',
    INN: 5045026047,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №2"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №2" городского округа Ступино Московской области',
    INN: 5045026135,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №4"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №4" городского округа Ступино Московской области',
    INN: 5045026086,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №5 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Средняя общеобразовательная школа №5 с углублённым изучением отдельных предметов» городского округа Ступино Московской области  ",
    INN: 5045025910,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №8 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №8 с углублённым изучением отдельных предметов" городского округа Ступино Московской области',
    INN: 5045025251,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №9"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №9" городского округа Ступино Московской области',
    INN: 5045040556,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization: 'МБОУ "Усадовская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Усадовская средняя общеобразовательная школа" городского округа Ступино Московской области',
    INN: 5045025942,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization: 'МКОУ "Коррекционная общеобразовательная школа №10"',
    FullEduОrganization:
      'Муниципальное казенное общеобразовательное учреждение "Коррекционная  общеобразовательная школа №10"',
    INN: 5045023818,
  },
  {
    Region: "Московская область",
    Municipality: "Ступино городской округ",
    EduОrganization: 'МКОУ "Коррекционная общеобразовательная школа-интернат"',
    FullEduОrganization:
      'Муниципальное казенное общеобразовательное учреждение "Коррекционная общеобразовательная школа-интернат"',
    INN: 5045025156,
  },
  {
    Region: "Московская область",
    Municipality: "Талдомский городской округ",
    EduОrganization: 'МОУ "Запрудненская гимназия"',
    FullEduОrganization:
      'муниципальное общеобразовательное учреждение "Запрудненская гимназия"',
    INN: 5078001908,
  },
  {
    Region: "Московская область",
    Municipality: "Талдомский городской округ",
    EduОrganization: "МОУ Вербилковская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Вербилковская средняя общеобразовательная школа",
    INN: 5078005268,
  },
  {
    Region: "Московская область",
    Municipality: "Талдомский городской округ",
    EduОrganization: 'МОУ гимназия "Детская школа искусств" им. А.А. Цветкова',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение гимназия "Детская школа искусств" им. А.А. Цветкова ',
    INN: 5078002732,
  },
  {
    Region: "Московская область",
    Municipality: "Талдомский городской округ",
    EduОrganization: "МОУ Запрудненская средняя общеобразовательная школа №1",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Запрудненская средняя общеобразовательная школа № 1",
    INN: 5078003503,
  },
  {
    Region: "Московская область",
    Municipality: "Талдомский городской округ",
    EduОrganization: "МОУ Квашенковская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Квашёнковская средняя общеобразовательная школа",
    INN: 5078004070,
  },
  {
    Region: "Московская область",
    Municipality: "Талдомский городской округ",
    EduОrganization: "МОУ Павловическая средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение Павловическая средняя общеобразовательная школа",
    INN: 5078003951,
  },
  {
    Region: "Московская область",
    Municipality: "Талдомский городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №1 г. Талдома",
    FullEduОrganization:
      "муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 1 г. Талдома",
    INN: 5078003278,
  },
  {
    Region: "Московская область",
    Municipality: "Талдомский городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа № 2 г. Талдома ",
    INN: 5078008100,
  },
  {
    Region: "Московская область",
    Municipality: "Талдомский городской округ",
    EduОrganization: "МОУ средняя общеобразовательная школа №3",
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа №3 г.Талдома ",
    INN: 5078002683,
  },
  {
    Region: "Московская область",
    Municipality: "Талдомский городской округ",
    EduОrganization:
      "МСОУ Запрудненская общеобразовательная школа-интернат для обучающихся с ограниченными возможностями здоровья",
    FullEduОrganization:
      "Муниципальное специальное общеобразовательное учреждение Запрудненская школа – интернат для обучающихся  с ограниченными возможностями здоровья ",
    INN: 5078008460,
  },
  {
    Region: "Московская область",
    Municipality: "Фрязино городской округ",
    EduОrganization: 'МБОО "Гимназия"',
    FullEduОrganization:
      'Муниципальная бюджетная общеобразовательная  организация "Гимназия" городского округа Фрязино',
    INN: 5052008391,
  },
  {
    Region: "Московская область",
    Municipality: "Фрязино городской округ",
    EduОrganization: 'МБОО "Лицей" им. Героя Советского Союза Б.Н. Еряшева',
    FullEduОrganization:
      'Муниципальная бюджетная общеобразовательная организация "Лицей" имени Героя Советского Союза Б.Н.Еряшева городского округа  Фрязино Московской области  ',
    INN: 5052008401,
  },
  {
    Region: "Московская область",
    Municipality: "Фрязино городской округ",
    EduОrganization:
      "МБОО Средняя общеобразовательная школа №1 им. Героя Советского Союза И.И. Иванова",
    FullEduОrganization:
      "Муниципальная бюджетная общеобразовательная организация средняя общеобразовательная  школа №1 имени Героя Советского Союза И.И. Иванова городского округа Фрязино Московской области",
    INN: 5052008384,
  },
  {
    Region: "Московская область",
    Municipality: "Фрязино городской округ",
    EduОrganization: "МБОО средняя общеобразовательная школа №2",
    FullEduОrganization:
      "Муниципальная бюджетная общеобразовательная организация средняя общеобразовательная школа № 2 городского округа Фрязино Московской области",
    INN: 5052008296,
  },
  {
    Region: "Московская область",
    Municipality: "Фрязино городской округ",
    EduОrganization:
      "МБОО Средняя общеобразовательная школа №3 им. Героя Советского Союза А.Г. Дудкина",
    FullEduОrganization:
      "Муниципальная бюджетная общеобразовательная организация средняя общеобразовательная школа № 3 имени Героя Советского Союза А.Г. Дудкина городского округа Фрязино Московской области",
    INN: 5052008338,
  },
  {
    Region: "Московская область",
    Municipality: "Фрязино городской округ",
    EduОrganization: "МБОО средняя общеобразовательная школа №4",
    FullEduОrganization:
      "Муниципальная бюджетная общеобразовательная организация средняя общеобразовательная школа № 4 г.о.Фрязино Московской области",
    INN: 5052008112,
  },
  {
    Region: "Московская область",
    Municipality: "Фрязино городской округ",
    EduОrganization: "МБОО средняя общеобразовательная школа №5",
    FullEduОrganization:
      "МУНИЦИПАЛЬНАЯ БЮДЖЕТНАЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ОРГАНИЗАЦИЯ СРЕДНЯЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА №5 С ГОРОДСКОГО ОКРУГА ФРЯЗИНО МОСКОВСКОЙ ОБЛАСТИ ",
    INN: 5052008218,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МAОУ Аэрокосмический лицей г. Химки",
    FullEduОrganization:
      "муниципальное автономное общеобразовательное учреждение Аэрокосмический лицей г.Химки ",
    INN: 5047276109,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: 'МАОУ "Образовательный комплекс "Перспектива"',
    FullEduОrganization:
      'муниципальное автономное общеобразовательное учреждение "Образовательный комплекс "Перспектива" г. Химки ',
    INN: 5047275923,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МАОУ Лицей №21 г. Химки",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Лицей № 21 г. Химки ",
    INN: 5047051419,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: 'МБОУ "Тридцать первая школа"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Средняя общеобразовательная школа № 31 г. Химки",
    INN: 5047275835,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: 'МБОУ "Школа равных возможностей" г. Химки',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Школа равных возможностей" г.Химки',
    INN: 5047047003,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МБОУ Гимназия №23 г. Химки",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Гимназия №23 г. Химки",
    INN: 5047045535,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МБОУ Гимназия №9 г. Химки",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Гимназия №9 г. Химки",
    INN: 5047052243,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МБОУ Кутузовская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Кутузовская средняя общеобразовательная школа",
    INN: 5044019488,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МБОУ Лицей №10 г. Химки",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Лицей № 10 г. Химки",
    INN: 5047049032,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МБОУ Лицей №12 г. Химки",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Лицей № 12 г. Химки",
    INN: 5047050969,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МБОУ Лицей №15 г. Химки",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение Лицей№15 г.Химки",
    INN: 5047276081,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МБОУ Лицей №7 им. Д.П. Уланова",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Лицей №7 имени Д. П. Уланова ",
    INN: 5047010194,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МБОУ Лунёвская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Луневская средняя общеобразовательная школа ",
    INN: 5044019216,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: 'МБОУ Средняя общеобразовательная школа "Лига первых"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Средняя общеобразовательная школа "Лига первых" г. Химки',
    INN: 5047275659,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: 'МБОУ Средняя общеобразовательная школа "Лидер" г. Химки',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение Cредняя общеобразовательная школа "Лидер" г. Химки',
    INN: 5047253447,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: 'МБОУ Средняя общеобразовательная школа "Наследие"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Средняя общеобразовательная школа "Наследие" г. Химки ',
    INN: 5047275803,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: 'МБОУ Средняя общеобразовательная школа "Флагман"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Средняя общеобразовательная школа "Флагман" г. Химки',
    INN: 5047275828,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №19 г. Химки",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение Средняя общеобразовательная школа №19 г. Химки",
    INN: 5047276099,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №1 г. Химки",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение Средняя общеобразовательная школа № 1 г.Химки",
    INN: 5047045503,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №20 г. Химки",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Средняя общеобразовательная школа №20 г. Химки",
    INN: 5047045510,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №29 г. Химки",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение Средняя общеобразовательная школа №29 г. Химки ",
    INN: 5047158747,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: "МБОУ Средняя общеобразовательная школа №30 г. Химки",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Средняя общеобразовательная школа № 30 г. Химки ",
    INN: 5047160930,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization:
      "МБОУ Средняя общеобразовательная школа №8 им. В.И. Матвеева",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 8 имени В.И. Матвеева ",
    INN: 5047045542,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization:
      "МБОУ Средняя общеобразовательная школа с углубленным изучением отдельных предметов №14 г.Химки",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Средняя общеобразовательная школа с углубленным изучением отдельных предметов №14 г.Химки  ",
    INN: 5047045493,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization:
      "МБОУ Средняя общеобразовательная школа с углубленным изучением отдельных предметов №22 г. Химки",
    FullEduОrganization:
      "муниципальное бюджетное общеобразовательное учреждение Средняя общеобразовательная школа с углубленным изучением отдельных предметов № 22 г. Химки",
    INN: 5047045486,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: 'МБОУ Школа "Триумф"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение Школа "Триумф"  г. Химки  ',
    INN: 5047275810,
  },
  {
    Region: "Московская область",
    Municipality: "Химки городской округ",
    EduОrganization: 'МБОУ школа-интернат "Кадетский корпус" г. Химки',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ШКОЛА-ИНТЕРНАТ "КАДЕТСКИЙ КОРПУС" Г.ХИМКИ',
    INN: 5047052331,
  },
  {
    Region: "Московская область",
    Municipality: "Черноголовка городской округ",
    EduОrganization:
      'МОУ "Черноголовская средняя общеобразовательная школа им. Ф.И. Дубовицкого и И.М. Халатникова"',
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение средняя общеобразовательная школа №82 имени Ф.И. Дубовицкого",
    INN: 5031027403,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization: 'МБОУ "Средняя школа №10"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Средняя школа №10"  ',
    INN: 5048034631,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization: "МБОУ гимназия №2",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение гимназия №2 ",
    INN: 5048052863,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization: "МБОУ гимназия №7",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение гимназия №7 ",
    INN: 5048052870,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization: "МБОУ Дубненская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Дубненская средняя общеобразовательная школа",
    INN: 5048053000,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization: "МБОУ Крюковская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Крюковская средняя общеобразовательная школа",
    INN: 5048052990,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization: "МБОУ лицей №4",
    FullEduОrganization:
      "Муниципальное бюджтное общеобразовательное учреждение лицей №4",
    INN: 5048052856,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization: "МБОУ Любучанская  средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Любучанская средняя общеобразовательная школа",
    INN: 5048052937,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization: "МБОУ Манушкинская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Манушкинская средняя общеобразовательная школа ",
    INN: 5048052800,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization: "МБОУ Нерастанновская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Нерастанновская средняя общеобразовательная школа ",
    INN: 5048052528,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization:
      "МБОУ Новобытовская средняя общеобразовательная школа с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Новобытовская средняя школа с углубленным изучением отдельных предметов",
    INN: 5048052687,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа №1 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №1 с углубленным изучением отдельных предметов",
    INN: 5048052895,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №3",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №3",
    INN: 5048052831,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа №8 с углубленным изучением отдельных предметов им. Героя Советского Союза А.И. Маркова",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №8 с углубленным изучением отдельных предметов имени Героя Советского Союза А.И. Маркова",
    INN: 5048052944,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа №9 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 9 с углубленным изучением отдельных предметов ",
    INN: 5048052905,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа Чехов-2",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа Чехов-2",
    INN: 5048053095,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа Чехов-3 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа Чехов-3 с углублённым  изучением отдельных предметов",
    INN: 5048052743,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа Чехов-7",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа Чехов-7",
    INN: 5048052790,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization: "МБОУ Столбовская средняя общеобразовательная школа",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Столбовская средняя общеобразовательная школа  ",
    INN: 5048052694,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization:
      "МБОУ Троицкая средняя общеобразовательная школа им. С.Н. Воронина",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Троицкая средняя общеобразовательная школа имени С.Н. Воронина ",
    INN: 5048053017,
  },
  {
    Region: "Московская область",
    Municipality: "Чехов городской округ",
    EduОrganization:
      'МКОУ "Чеховская школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Чеховская школа-интернат для обучающихся с ограниченными возможностями здоровья""',
    INN: 5048052912,
  },
  {
    Region: "Московская область",
    Municipality: "Шатура городской округ",
    EduОrganization: 'МБОУ "Коробовский лицей"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Коробовский лицей Городского округа Шатура"',
    INN: 5049012327,
  },
  {
    Region: "Московская область",
    Municipality: "Шатура городской округ",
    EduОrganization: 'МБОУ "Лицей г. Шатуры"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Лицей города Шатуры Городского округа Шатура"',
    INN: 5049012172,
  },
  {
    Region: "Московская область",
    Municipality: "Шатура городской округ",
    EduОrganization: 'МБОУ "Лицей им. Героя Советского Союза В.В. Гусева"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Лицей имени Героя Советского Союза В.В. Гусева города Рошаль Городского округа Шатура»",
    INN: 5055001267,
  },
  {
    Region: "Московская область",
    Municipality: "Шатура городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №1 г. Шатуры"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 1 города Шатуры Городского округа Шатура"',
    INN: 5049012430,
  },
  {
    Region: "Московская область",
    Municipality: "Шатура городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №2 г. Рошаль"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 2 города Рошаль Городского округа Шатура"',
    INN: 5055001228,
  },
  {
    Region: "Московская область",
    Municipality: "Шатура городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа №2 г. Шатуры"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа №2 города Шатуры Городского округа Шатура"',
    INN: 5049012207,
  },
  {
    Region: "Московская область",
    Municipality: "Шатура городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №4 им. Героя Советского Союза Ф.Т. Жарова г. Шатуры"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 4 имени Героя Советского Союза Ф.Т. Жарова города Шатуры Городского округа Шатура"',
    INN: 5049012260,
  },
  {
    Region: "Московская область",
    Municipality: "Шатура городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа №6 им. А.Ю. Малинина"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа № 6 имени А.Ю. Малинина города Рошаль Городского округа Шатура"',
    INN: 5055001250,
  },
  {
    Region: "Московская область",
    Municipality: "Шатура городской округ",
    EduОrganization:
      'МБОУ "Средняя общеобразовательная школа им. Героя Советского Союза Н.П. Кочеткова с. Пышлицы"',
    FullEduОrganization:
      "Муниципальное  бюджетное общеобразовательное  учреждение «Средняя  общеобразовательная школа   имени  Героя  Советского  Союза  Н.П.Кочеткова  села  Пышлицы Городского округа Шатура»",
    INN: 5049012140,
  },
  {
    Region: "Московская область",
    Municipality: "Шатура городской округ",
    EduОrganization: 'МБОУ "Средняя общеобразовательная школа с. Кривандино"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Средняя общеобразовательная школа села Кривандино Городского округа Шатура" ',
    INN: 5049012246,
  },
  {
    Region: "Московская область",
    Municipality: "Шатура городской округ",
    EduОrganization:
      'МБОУ "Школа-интернат для детей с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Школа-интернат для детей с ограниченными возможностями здоровья Городского округа Шатура"  ',
    INN: 5049012768,
  },
  {
    Region: "Московская область",
    Municipality: "Шаховская городской округ",
    EduОrganization: 'МБОУ "Белоколпская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Белоколпская средняя общеобразовательная школа" ',
    INN: 5079006585,
  },
  {
    Region: "Московская область",
    Municipality: "Шаховская городской округ",
    EduОrganization: 'МБОУ "Дорская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Дорская средняя общеобразовательная школа"',
    INN: 5079006560,
  },
  {
    Region: "Московская область",
    Municipality: "Шаховская городской округ",
    EduОrganization: 'МБОУ "Дубранивская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение " Дубранивская средняя общеобразовательная школа" ',
    INN: 5079006553,
  },
  {
    Region: "Московская область",
    Municipality: "Шаховская городской округ",
    EduОrganization: 'МБОУ "Ивашковская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Ивашковская средняя общеобразовательная школа"',
    INN: 5079006546,
  },
  {
    Region: "Московская область",
    Municipality: "Шаховская городской округ",
    EduОrganization: 'МБОУ "Раменская средняя общеобразовательная школа"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Раменская средняя общеобразовательная школа"',
    INN: 5079006521,
  },
  {
    Region: "Московская область",
    Municipality: "Шаховская городской округ",
    EduОrganization: 'МБОУ "Серединская средняя общеобразовательная школа"',
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение «Серединская средняя общеобразовательная школа» ",
    INN: 5079006514,
  },
  {
    Region: "Московская область",
    Municipality: "Шаховская городской округ",
    EduОrganization: 'МБОУ "Шаховская гимназия"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Шаховская гимназия" ',
    INN: 5079003048,
  },
  {
    Region: "Московская область",
    Municipality: "Шаховская городской округ",
    EduОrganization: 'МБОУ "Шаховская средняя общеобразовательная школа №1"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Шаховская средняя общеобразовательная школа №1"',
    INN: 5079006507,
  },
  {
    Region: "Московская область",
    Municipality: "Шаховская городской округ",
    EduОrganization:
      'МКОУ "Шаховская школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное казенное общеобразовательное учреждение "Шаховская школа-интернат для обучающихся с ограниченными возможностями здоровья"',
    INN: 5079006634,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization: 'МАОУ "Гимназия №2"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение "Гимназия №2" городского округа Щёлково',
    INN: 5050032039,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization: 'МАОУ "Щелковская гимназия №6"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение "Щёлковская гимназия № 6" городского округа Щёлково',
    INN: 5050031902,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization: "МАОУ Лицей №14 им. Ю.А. Гагарина",
    FullEduОrganization:
      "МУНИЦИПАЛЬНОЕ АВТОНОМНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ЛИЦЕЙ №14 ИМЕНИ Ю.А. ГАГАРИНА ГОРОДСКОГО ОКРУГА ЩЁЛКОВО",
    INN: 5050031927,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization:
      "МАОУ Медвежье-Озерская средняя общеобразовательная школа №19 им. Героя Российской Федерации О.Г. Ильина",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение Медвежье-Озёрская средняя общеобразовательная школа № 19 имени Героя Российской Федерации О.Г.Ильина городского округа Щёлково ",
    INN: 5050032092,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization:
      "МАОУ средняя общеобразовательная школа №11 им. Г.С. Титова",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение средняя общеобразовательная школа № 11 имени Г.С. Титова городского округа Щёлково",
    INN: 5050031839,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization:
      "МАОУ средняя общеобразовательная школа №12 им. В.П. Чкалова",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение средняя общеобразовательная школа №12 имени В.П.Чкалова городского округа Щёлково ",
    INN: 5050032078,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization: "МАОУ средняя общеобразовательная школа №16",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение средняя общеобразовательная школа №16 городского округа Щёлково ",
    INN: 5050032134,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization:
      "МАОУ средняя общеобразовательная школа №17 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение средняя общеобразовательная школа №17 с углубленным изучением отдельных предметов городского округа Щёлково",
    INN: 5050119057,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization: "МАОУ средняя общеобразовательная школа №21",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение средняя общеобразовательная школа №21 городского округа Щёлково Московской области",
    INN: 5050030320,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization: "МАОУ средняя общеобразовательная школа №3",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение средняя общеобразовательная школа №3 городского округа Щёлково",
    INN: 5050031998,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization:
      "МАОУ средняя общеобразовательная школа №4 им. П.И. Климука",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение средняя общеобразовательная школа №4 имени П.И. Климука городского округа Щёлково",
    INN: 5050040706,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization: "МАОУ средняя общеобразовательная школа №8",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение средняя общеобразовательная школа №8 городского округа Щёлково  ",
    INN: 5050031966,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization:
      'МБОУ "Школа №5 для обучающихся с ограниченными возможностями здоровья"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Школа №5 для обучающихся с ограниченными возможностями здоровья" городского округа Щёлково',
    INN: 5050032127,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа №10 с углубленным изучением отдельных предметов",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №10 с углубленным изучением отдельных предметов городского округа Щёлково  ",
    INN: 5050031959,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа №13 им. В.А. Джанибекова",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 13 имени В.А. Джанибекова городского округа Щёлково",
    INN: 5050031892,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №20",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №20 городского округа Щёлково ",
    INN: 5050032060,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа №24 им. С.А. Красовского",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 24 имени С.А. Красовского городского округа Щёлково ",
    INN: 5050019768,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization:
      "МБОУ средняя общеобразовательная школа №25 им. И.А. Копылова",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №25 имени И.А.Копылова городского округа Щёлково",
    INN: 5050034491,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №26",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа №26 городского округа Щёлково",
    INN: 5050032014,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №27",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение средняя общеобразовательная школа № 27 городского округа Щелково",
    INN: 5050032102,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization: "МБОУ средняя общеобразовательная школа №28",
    FullEduОrganization:
      "Муниципальное автономное общеобразовательное учреждение средняя общеобразовательная школа N28 городского округа Щёлково",
    INN: 5050020185,
  },
  {
    Region: "Московская область",
    Municipality: "Щелково городской округ",
    EduОrganization: "МБОУ Щелковский лицей №7",
    FullEduОrganization:
      "Муниципальное бюджетное общеобразовательное учреждение Щёлковский лицей № 7 городского округа Щёлково ",
    INN: 5050017344,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization:
      'МАОУ "Средняя общеобразовательная школа №13 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное автономное общеобразовательное учреждение "Средняя общеобразовательная школа № 13 с углублённым изучением отдельных предметов"',
    INN: 5053004520,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization:
      'МБОУ "Степановская средняя общеобразовательная школа им.Б.А. Воробьева"',
    FullEduОrganization:
      'Муниципальное бюджетное общеобразовательное учреждение "Степановская средняя общеобразовательная школа  имени Б.А. Воробьева"',
    INN: 5031028654,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization:
      'МБОУ "Школа-интернат для детей с ограниченными возможностями здоровья №1"',
    FullEduОrganization:
      'муниципальное бюджетное общеобразовательное учреждение "Школа-интернат для детей с ограниченными возможностями здоровья № 1 городского округа Электросталь Московской области"  ',
    INN: 5053003090,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization: 'МОУ "Гимназия №17"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Гимназия № 17"',
    INN: 5053002762,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization: 'МОУ "Гимназия №21"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Гимназия №21" ',
    INN: 5053003830,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization: 'МОУ "Гимназия №4"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Гимназия №4"',
    INN: 5053003163,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization: 'МОУ "Гимназия №9"',
    FullEduОrganization:
      'МУНИЦИПАЛЬНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ "ГИМНАЗИЯ №9" ',
    INN: 5053014493,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization: 'МОУ "Лицей №14"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей №14"',
    INN: 5053014849,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization: 'МОУ "Лицей №7"',
    FullEduОrganization:
      'муниципальное общеобразовательное учреждение "Лицей №7"',
    INN: 5053011460,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization: 'МОУ "Лицей №8"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Лицей №8"',
    INN: 5053012915,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №11"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №11"  ',
    INN: 5053003967,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization:
      'МОУ "Средняя общеобразовательная школа №12 с углубленным изучением иностранного языка"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №12 с углубленным изучением иностранного языка"  ',
    INN: 5053003156,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №1"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №1"',
    INN: 5053003396,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization:
      'МОУ "Средняя общеобразовательная школа №15 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение «Средняя общеобразовательная школа №15 с углублённым изучением отдельных предметов»",
    INN: 5053003364,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization:
      'МОУ "Средняя общеобразовательная школа №16 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №16 с Углубленным изучением отдельных предметов"',
    INN: 5053002931,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №18"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №18"',
    INN: 5053002995,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization:
      'МОУ "Средняя общеобразовательная школа №22 с углубленным изучением отдельных предметов"',
    FullEduОrganization:
      'Муниципальное общеобразовательное учреждение "Средняя общеобразовательная школа №22 с углубленным изучением отдельных предметов" ',
    INN: 5053002970,
  },
  {
    Region: "Московская область",
    Municipality: "Электросталь городской округ",
    EduОrganization: 'МОУ "Средняя общеобразовательная школа №5"',
    FullEduОrganization:
      "Муниципальное общеобразовательное учреждение «Средняя общеобразовательная школа № 5»  ",
    INN: 5053003082,
  },
];
