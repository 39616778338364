import React, { useState, useEffect } from "react";
import { organization } from "../../constants/organization";
import InputField from "./InputField";
import TextareaField from "./TextareaField";
import CheckboxField from "./CheckboxField";
import CustomSelect from "./CustomSelect";
import CompanySearchField from "./SearchField";
import { program } from "../../constants/program";
import { age } from "../../constants/age";
import { category } from "../../constants/category";

const Form = () => {
  const [regions, setRegions] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [state, setState] = useState({
    formData: {
      region: "",
      municipality: "",
      organization: "",
      name: "",
      position: "",
      program: "",
      age: "",
      category: "",
      phone: "",
      email: "",
      comment: "",
      privacy: false,
    },
    errors: {
      region: "",
      municipality: "",
      organization: "",
      name: "",
      position: "",
      program: "",
      age: "",
      category: "",
      phone: "",
      email: "",
      privacy: "",
    },
    showDropdown: {
      region: false,
      municipality: false,
      organization: false,
      program: false,
      age: false,
      category: false,
    },
    isFormValid: false,
    isSubmitAttempted: false,
  });

  useEffect(() => {
    const uniqueRegions = [...new Set(organization.map((org) => org.Region))];
    setRegions(
      uniqueRegions.map((region) => ({ label: region, value: region }))
    );

    const formattedPrograms = program.map((prog) => ({
      label: prog,
      value: prog,
    }));
    setProgramOptions(formattedPrograms);

    const formattedAges = age.map((ageRange) => ({
      label: ageRange,
      value: ageRange,
    }));
    setAgeOptions(formattedAges);

    const formattedCategories = category.map((cat) => ({
      label: cat,
      value: cat,
    }));
    setCategoryOptions(formattedCategories);
  }, []);

  useEffect(() => {
    // Проверяем, заполнены ли все поля и нет ли ошибок
    const newErrors = validateFields(state.formData);

    const isFormValid = !Object.values(newErrors).some((error) => error !== "");

    setState((prevState) => ({
      ...prevState,
      errors: newErrors,
      isFormValid,
    }));
  }, [state.formData]);

  const validateFields = (formData) => {
    return {
      region: formData.region.trim() ? "" : "Поле обязательно для заполнения",
      municipality: formData.municipality.trim()
        ? ""
        : "Поле обязательно для заполнения",
      organization: formData.organization.trim()
        ? ""
        : "Поле обязательно для заполнения",
      program: formData.program.trim() ? "" : "Поле обязательно для заполнения",
      name: formData.name.trim() ? "" : "Поле обязательно для заполнения",
      position: formData.position.trim()
        ? ""
        : "Поле обязательно для заполнения",
      age: formData.age.trim() ? "" : "Поле обязательно для заполнения",
      category: formData.category.trim()
        ? ""
        : "Поле обязательно для заполнения",
      phone:
        formData.phone.trim() &&
        /^\+7 \d{3} \d{3} \d{2} \d{2}$/.test(formData.phone)
          ? ""
          : "Укажите корректный номер",
      email:
        formData.email.trim() &&
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
          ? ""
          : "Укажите корректный Email",
      privacy: formData.privacy ? "" : "Поле обязательно для заполнения",
    };
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    let inputValue = type === "checkbox" ? e.target.checked : value;

    if (name === "phone") {
      let phoneNumber = value.replace(/\D/g, "");

      if (phoneNumber.length > 11) {
        phoneNumber = phoneNumber.slice(0, 11);
      }

      if (phoneNumber.startsWith("7") || phoneNumber.startsWith("8")) {
        phoneNumber = phoneNumber.slice(1);
      }

      if (phoneNumber.length > 0) {
        phoneNumber = phoneNumber.replace(
          /(\d{1,3})(\d{1,3})?(\d{1,2})?(\d{1,2})?/,
          (match, p1, p2, p3, p4) => {
            let formatted = p1;
            if (p2) formatted += " " + p2;
            if (p3) formatted += " " + p3;
            if (p4) formatted += " " + p4;
            return formatted;
          }
        );
      }

      inputValue = "+7 " + phoneNumber;
    }

    setState((prevState) => ({
      ...prevState,
      formData: { ...prevState.formData, [name]: inputValue },
      errors: { ...prevState.errors, [name]: "" },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isSubmitAttempted: true,
    }));

    const newErrors = validateFields(state.formData);

    if (!validateForm(newErrors)) return;

    if (isSubmitting) return;

    setIsSubmitting(true);

    const formData = {
      region: state.formData.region,
      municipality: state.formData.municipality,
      organization: state.formData.organization,
      program: state.formData.program,
      age: state.formData.age,
      name: state.formData.name,
      comment: state.formData.comment,
      position: state.formData.position,
      category: state.formData.category,
      phone: state.formData.phone,
      email: state.formData.email,
    };

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbyOKd82QXVvqmfi1AzNnu_6Vjs3Ucf0M7v9ET88hCGItw1GWZ4qweQTBJ-5AF33KZc/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(formData).toString(),
        }
      );

      if (response.ok) {
        console.log("Форма успешно отправлена");
        window.location.href = "/thanks";
      } else {
        console.error("Ошибка при отправке формы");
      }
    } catch (error) {
      console.error("Не удалось отправить данные", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateForm = (newErrors) => {
    setState((prevState) => ({
      ...prevState,
      errors: newErrors,
      isFormValid: !Object.values(newErrors).some((error) => error !== ""),
    }));

    return !Object.values(newErrors).some((error) => error !== "");
  };

  const handleRegionChange = (option) => {
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        region: option.value,
        municipality: "",
        organization: "",
      },
      errors: { ...prevState.errors, region: "" },
      showDropdown: {
        ...prevState.showDropdown,
        region: false,
      },
    }));

    const filteredMunicipalities = [
      ...new Set(
        organization
          .filter((org) => org.Region === option.value)
          .map((org) => org.Municipality)
      ),
    ].map((municipality) => ({ label: municipality, value: municipality }));

    setMunicipalities(filteredMunicipalities);
    setOrganizations([]); // Сбрасываем организации при выборе нового региона
  };

  const handleMunicipalityChange = (e) => {
    const selectedMunicipality = e.target.value;
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        municipality: selectedMunicipality,
        organization: "",
      },
      errors: { ...prevState.errors, municipality: "" },
      showDropdown: { ...prevState.showDropdown, municipality: false },
    }));

    const filteredOrganizations = organization
      .filter(
        (org) =>
          org.Region === state.formData.region &&
          org.Municipality === selectedMunicipality
      )
      .map((org) => ({
        label: org.EduОrganization,
        value: org.EduОrganization,
      }));

    setOrganizations(filteredOrganizations);
  };

  const handleOrganizationChange = (e) => {
    const selectedOrganization = e.target.value;
    setState((prevState) => ({
      ...prevState,
      formData: { ...prevState.formData, organization: selectedOrganization },
      errors: { ...prevState.errors, organization: "" },
      showDropdown: { ...prevState.showDropdown, organization: false },
    }));
  };

  const handleProgramChange = (option) => {
    setState((prevState) => ({
      ...prevState,
      formData: { ...prevState.formData, program: option.value },
      errors: { ...prevState.errors, program: "" },
      showDropdown: { ...prevState.showDropdown, program: false },
    }));
  };

  const handleAgeChange = (option) => {
    setState((prevState) => ({
      ...prevState,
      formData: { ...prevState.formData, age: option.value },
      errors: { ...prevState.errors, age: "" },
      showDropdown: { ...prevState.showDropdown, age: false },
    }));
  };

  const handleCategoryChange = (option) => {
    setState((prevState) => ({
      ...prevState,
      formData: { ...prevState.formData, category: option.value },
      errors: { ...prevState.errors, category: "" },
      showDropdown: { ...prevState.showDropdown, category: false },
    }));
  };

  const handleFocus = (field) => {
    setState((prevState) => ({
      ...prevState,
      showDropdown: { ...prevState.showDropdown, [field]: true },
    }));
  };

  const handleBlur = (field) => {
    setState((prevState) => ({
      ...prevState,
      showDropdown: { ...prevState.showDropdown, [field]: false },
    }));
  };

  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      className="flex flex-col justify-center items-center gap-3.5"
    >
      <CustomSelect
        selectedValue={state.formData.region}
        options={regions}
        error={state.isSubmitAttempted ? state.errors.region : null}
        showDropdown={state.showDropdown.region}
        onOptionClick={handleRegionChange}
        onFocus={() => handleFocus("region")}
        onBlur={() => handleBlur("region")}
        placeholder="Регион"
      />

      <CompanySearchField
        query={state.formData.municipality}
        options={municipalities}
        error={state.isSubmitAttempted ? state.errors.municipality : null}
        showDropdown={state.showDropdown.municipality}
        onQueryChange={handleMunicipalityChange}
        onOptionClick={(option) =>
          handleMunicipalityChange({ target: { value: option.value } })
        }
        onFocus={() => handleFocus("municipality")}
        onBlur={() => handleBlur("municipality")}
        placeholder="Муниципалитет"
        placeholder2="Название муниципалитета"
      />

      <CompanySearchField
        query={state.formData.organization}
        options={organizations}
        error={state.isSubmitAttempted ? state.errors.organization : null}
        showDropdown={state.showDropdown.organization}
        onQueryChange={handleOrganizationChange}
        onOptionClick={(option) =>
          handleOrganizationChange({ target: { value: option.value } })
        }
        onFocus={() => handleFocus("organization")}
        onBlur={() => handleBlur("organization")}
        placeholder="Название организации"
        placeholder2="Название организации"
      />

      <InputField
        name="name"
        type="text"
        placeholder="ФИО"
        value={state.formData.name}
        required
        error={state.isSubmitAttempted ? state.errors.name : ""}
        onChange={handleInputChange}
        className="py-3.5"
      />

      <InputField
        name="position"
        type="text"
        placeholder="Должность"
        required
        value={state.formData.position}
        error={state.isSubmitAttempted ? state.errors.position : ""}
        onChange={handleInputChange}
        className="py-3.5"
      />

      <CustomSelect
        selectedValue={state.formData.age}
        options={ageOptions}
        error={state.isSubmitAttempted ? state.errors.age : null}
        showDropdown={state.showDropdown.age}
        onOptionClick={handleAgeChange}
        onFocus={() => handleFocus("age")}
        onBlur={() => handleBlur("age")}
        placeholder="Возрастная категория"
      />

      <CustomSelect
        selectedValue={state.formData.category}
        options={categoryOptions}
        error={state.isSubmitAttempted ? state.errors.category : null}
        showDropdown={state.showDropdown.category}
        onOptionClick={handleCategoryChange}
        onFocus={() => handleFocus("category")}
        onBlur={() => handleBlur("category")}
        placeholder="К какой категории лиц вы относитесь?"
      />

      <CustomSelect
        selectedValue={state.formData.program}
        options={programOptions}
        error={state.isSubmitAttempted ? state.errors.program : null}
        showDropdown={state.showDropdown.program}
        onOptionClick={handleProgramChange}
        onFocus={() => handleFocus("program")}
        onBlur={() => handleBlur("program")}
        placeholder="Выбор программы обучения"
      />

      <InputField
        name="phone"
        type="tel"
        placeholder="Номер телефона"
        value={state.formData.phone}
        required
        error={state.isSubmitAttempted ? state.errors.phone : ""}
        onChange={handleInputChange}
        className="py-3.5"
      />

      <InputField
        name="email"
        type="email"
        placeholder="E-mail"
        value={state.formData.email}
        required
        error={state.isSubmitAttempted ? state.errors.email : ""}
        onChange={handleInputChange}
        className="py-3.5"
      />

      <TextareaField
        name="comment"
        placeholder="Комментарий"
        value={state.formData.comment}
        error=""
        onChange={handleInputChange}
        className="py-3.5"
      />

      <div className="pt-6">
        <CheckboxField
          name="privacy"
          checked={state.formData.privacy}
          onChange={handleInputChange}
          label={
            <a className="underline" href="/policy" target="_blank">
              Я принимаю политику приватности компании
            </a>
          }
          error={state.isSubmitAttempted ? state.errors.privacy : ""}
        />
      </div>

      <div className="relative w-full">
        {isSubmitting && (
          <div className="absolute inset-0 flex items-center justify-center w-fit top-4 mob:top-5 left-0 z-10">
            <svg
              className="w-18"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <g>
                <g transform="rotate(0 50 50)">
                  <rect
                    className="fill-main-white"
                    height="3"
                    width="3"
                    ry="0"
                    rx="0"
                    y="28.5"
                    x="48.5"
                  >
                    <animate
                      repeatCount="indefinite"
                      begin="-0.9166666666666666s"
                      dur="1s"
                      keyTimes="0;1"
                      values="1;0"
                      attributeName="opacity"
                    />
                  </rect>
                </g>
                <g transform="rotate(30 50 50)">
                  <rect
                    className="fill-main-white"
                    height="3"
                    width="3"
                    ry="0"
                    rx="0"
                    y="28.5"
                    x="48.5"
                  >
                    <animate
                      repeatCount="indefinite"
                      begin="-0.8333333333333334s"
                      dur="1s"
                      keyTimes="0;1"
                      values="1;0"
                      attributeName="opacity"
                    />
                  </rect>
                </g>
                <g transform="rotate(60 50 50)">
                  <rect
                    className="fill-main-white"
                    height="3"
                    width="3"
                    ry="0"
                    rx="0"
                    y="28.5"
                    x="48.5"
                  >
                    <animate
                      repeatCount="indefinite"
                      begin="-0.75s"
                      dur="1s"
                      keyTimes="0;1"
                      values="1;0"
                      attributeName="opacity"
                    />
                  </rect>
                </g>
                <g transform="rotate(90 50 50)">
                  <rect
                    className="fill-main-white"
                    height="3"
                    width="3"
                    ry="0"
                    rx="0"
                    y="28.5"
                    x="48.5"
                  >
                    <animate
                      repeatCount="indefinite"
                      begin="-0.6666666666666666s"
                      dur="1s"
                      keyTimes="0;1"
                      values="1;0"
                      attributeName="opacity"
                    />
                  </rect>
                </g>
                <g transform="rotate(120 50 50)">
                  <rect
                    className="fill-main-white"
                    height="3"
                    width="3"
                    ry="0"
                    rx="0"
                    y="28.5"
                    x="48.5"
                  >
                    <animate
                      repeatCount="indefinite"
                      begin="-0.5833333333333334s"
                      dur="1s"
                      keyTimes="0;1"
                      values="1;0"
                      attributeName="opacity"
                    />
                  </rect>
                </g>
                <g transform="rotate(150 50 50)">
                  <rect
                    className="fill-main-white"
                    height="3"
                    width="3"
                    ry="0"
                    rx="0"
                    y="28.5"
                    x="48.5"
                  >
                    <animate
                      repeatCount="indefinite"
                      begin="-0.5s"
                      dur="1s"
                      keyTimes="0;1"
                      values="1;0"
                      attributeName="opacity"
                    />
                  </rect>
                </g>
                <g transform="rotate(180 50 50)">
                  <rect
                    className="fill-main-white"
                    height="3"
                    width="3"
                    ry="0"
                    rx="0"
                    y="28.5"
                    x="48.5"
                  >
                    <animate
                      repeatCount="indefinite"
                      begin="-0.4166666666666667s"
                      dur="1s"
                      keyTimes="0;1"
                      values="1;0"
                      attributeName="opacity"
                    />
                  </rect>
                </g>
                <g transform="rotate(210 50 50)">
                  <rect
                    className="fill-main-white"
                    height="3"
                    width="3"
                    ry="0"
                    rx="0"
                    y="28.5"
                    x="48.5"
                  >
                    <animate
                      repeatCount="indefinite"
                      begin="-0.3333333333333333s"
                      dur="1s"
                      keyTimes="0;1"
                      values="1;0"
                      attributeName="opacity"
                    />
                  </rect>
                </g>
                <g transform="rotate(240 50 50)">
                  <rect
                    className="fill-main-white"
                    height="3"
                    width="3"
                    ry="0"
                    rx="0"
                    y="28.5"
                    x="48.5"
                  >
                    <animate
                      repeatCount="indefinite"
                      begin="-0.25s"
                      dur="1s"
                      keyTimes="0;1"
                      values="1;0"
                      attributeName="opacity"
                    />
                  </rect>
                </g>
                <g transform="rotate(270 50 50)">
                  <rect
                    className="fill-main-white"
                    height="3"
                    width="3"
                    ry="0"
                    rx="0"
                    y="28.5"
                    x="48.5"
                  >
                    <animate
                      repeatCount="indefinite"
                      begin="-0.16666666666666666s"
                      dur="1s"
                      keyTimes="0;1"
                      values="1;0"
                      attributeName="opacity"
                    />
                  </rect>
                </g>
                <g transform="rotate(300 50 50)">
                  <rect
                    className="fill-main-white"
                    height="3"
                    width="3"
                    ry="0"
                    rx="0"
                    y="28.5"
                    x="48.5"
                  >
                    <animate
                      repeatCount="indefinite"
                      begin="-0.08333333333333333s"
                      dur="1s"
                      keyTimes="0;1"
                      values="1;0"
                      attributeName="opacity"
                    />
                  </rect>
                </g>
                <g transform="rotate(330 50 50)">
                  <rect
                    className="fill-main-white"
                    height="3"
                    width="3"
                    ry="0"
                    rx="0"
                    y="28.5"
                    x="48.5"
                  >
                    <animate
                      repeatCount="indefinite"
                      begin="0s"
                      dur="1s"
                      keyTimes="0;1"
                      values="1;0"
                      attributeName="opacity"
                    />
                  </rect>
                </g>
                <g />
              </g>
            </svg>
          </div>
        )}
        <input
          type="submit"
          value="Отправить"
          className={`whitespace-nowrap relative z-0 text-f18 font-medium mt-4  transition-all rounded-full flex justify-center items-center w-full py-4 mob:mt-5 mob:text-f16 ${
            state.isFormValid
              ? "text-main-white bg-main-orange hover:bg-main-dorange cursor-pointer"
              : "text-[#949494] bg-main-awhite3 cursor-not-allowed"
          } ${isSubmitting ? "cursor-not-allowed opacity-70" : ""}`}
          disabled={isSubmitting}
        />
      </div>
    </form>
  );
};

export default Form;
